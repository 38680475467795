import React, { useCallback, useEffect } from "react";
// import { useRouter } from "next/router";
import { useDispatch } from "react-redux";
import { logout } from "src/slices/auth";
import routes from "src/routes";
import { isTokenExpired } from "src/utils/jwt";
import axios from "axios";
import toast from "react-hot-toast";
import { getTranslateString } from "src/utils/translate";
import { NETWORK_ERROR } from "src/constants/translate-keys/common";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

let isRefreshing = false;

let failedQueue: any[] = [];

const processQueue = (error: any, token: string | null = null) => {
  failedQueue.forEach(prom => {
    if (error) prom.reject(error);
    else prom.resolve(token);
  });

  failedQueue = [];
};

export type HttpContextContent = {
  http: any;
  apiEndpoint: typeof apiEndpoint;
};

// do this to ignore null error
export const HttpContext = React.createContext<HttpContextContent>({} as HttpContextContent);

const HttpProvider = ({ children }: { children: React.ReactNode }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const { pathname } = location;
  const accessToken = typeof window !== "undefined" ? localStorage.getItem("accessToken") : null;

  let baseURL =
    process.env.REACT_APP_IS_DEV === "true"
      ? process.env.REACT_APP_BASE_URL
      : typeof window !== "undefined"
      ? "/api/"
      : process.env.REACT_APP_BASE_URL;

  const http = axios.create({
    baseURL: baseURL,
    headers: {
      "Content-Type": "application/json",
      common: {
        ...(accessToken && { Authorization: `Bearer ${accessToken}` }),
      },
    },
  });

  const handleClearTokens = useCallback(() => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("remember_me");

    delete http.defaults.headers.common["Authorization"];

    dispatch(logout());

    // navigate(routes.ROOT, { replace: true });
  }, [http.defaults.headers.common, dispatch]);

  useEffect(() => {
    const refreshToken = localStorage.getItem("refreshToken");
    // if path is submit, submit_result and public, do not move forward, if not user will keep getting redirected to login
    if (pathname === routes.ROOT || pathname === routes.LOGIN || pathname === routes.CONFIRM_EMAIL) return;

    if (!refreshToken || (refreshToken && isTokenExpired(refreshToken))) {
      handleClearTokens();
    }
  }, [handleClearTokens, pathname]);

  http.interceptors.response.use(
    response => {
      return response;
    },
    async error => {
      if (error.message === "Network Error") {
        toast.error(getTranslateString(t, NETWORK_ERROR));

        return Promise.reject(error);
      }

      const originalRequest = error.config;

      if (
        error.response &&
        Object.keys(error.response).includes("status") &&
        error.response.status === 401 &&
        !originalRequest._retry &&
        !originalRequest.withCredentials
      ) {
        if (isRefreshing) {
          return new Promise((resolve, reject) => failedQueue.push({ resolve, reject }))
            .then(token => {
              originalRequest.headers["Authorization"] = `Bearer ${token}`;
              return http(originalRequest);
            })
            .catch(err => {
              return Promise.reject(err);
            });
        }

        originalRequest._retry = true;
        isRefreshing = true;

        return new Promise(async (resolve, reject) => {
          const refresh = localStorage.getItem("refreshToken");

          if ((!refresh || isTokenExpired(refresh)) && localStorage.getItem("remember_me") !== "true") {
            handleClearTokens();
          } else {
            axios
              .post(`${baseURL}${apiEndpoint.TOKEN_REFRESH.substring(1)}`, {
                refresh: refresh,
              })
              .then(res => {
                localStorage.setItem("accessToken", res.data.access);

                http.defaults.headers.common["Authorization"] = `Bearer ${res.data.access}`;
                originalRequest.headers["Authorization"] = `Bearer ${res.data.access}`;

                processQueue(null, res.data.access);
                resolve(http(originalRequest));
              })
              .catch(error => {
                if (error.response) {
                  if (error.response.status === 401) {
                    //need to use axios in order to get this 401
                    handleClearTokens();
                  }
                }
                processQueue(error, null);
                reject(error);
              })
              .finally(() => {
                isRefreshing = false;
              });

            // try {
            //   const { data } = await http.post(apiEndpoint.TOKEN_REFRESH, { refresh });

            //   localStorage.setItem("accessToken", data.access);

            //   http.defaults.headers.common["Authorization"] = `Bearer ${data.access}`;
            //   originalRequest.headers["Authorization"] = `Bearer ${data.access}`;

            //   processQueue(null, data.access);
            //   resolve(http(originalRequest));
            // } catch (error) {
            //   processQueue(error, null);
            //   reject(error);
            // } finally {
            //   isRefreshing = false;
            // }
          }
        });
      }

      return Promise.reject(error);
    }
  );

  return <HttpContext.Provider value={{ http, apiEndpoint }}>{children}</HttpContext.Provider>;
};

export default HttpProvider;

export const apiEndpoint = {
  CONFIGS: `/configs/`,
  TOKEN: `/auth/login/`,
  GOOGLE_AUTH: `/auth/google/`,
  FACEBOOK_AUTH: `/auth/facebook/`,
  USER_CREDIT: `/users/credits/`,
  SIGN_UP: `/auth/registration/`,
  FORGOT_PASSWORD: "/auth/password/reset/",
  RESET_PASSWORD: "/auth/password/reset/confirm/",
  TOKEN_REFRESH: `/auth/token/refresh/`,
  PERMISSIONS: `/users/permissions/`,
  INSTAGRAM: "/v1/p/instagram/",
  USER_SLOT: "/users/slot/",
  USER_PROFILE: "/v1/account/me/",
  CONFIRM_EMAIL: "/v1/account/me/confirm-email/",
  SEND_EMAIL_VERIFICATION: "/v1/account/me/verify-email-request/",
  STRIPE_CREATE_CUSTOMER: "/stripe/create-customer/",
  STRIPE_SUBSCRIPTION: "/v1/account/me/stripe_subscription/",
  STRIPE_SUBSCRIPTION_PLAN: "/stripe/subscription_plan/",
  STRIPE_CUSTOMER_SESSION: "/v1/account/me/stripe_customer_session/",
  STRIPE_CUSTOMER_PORTAL: "/v1/account/me/stripe_customer_portal/",
  USER_PROFILE_CHANGE_PASSWORD: "/v1/account/me/change-password/",
  USER_PROFILE_ACTIVITY_LOG: "/v1/account/me/activity_log/",
  INTERACTIVE_WALKTHROUGH_STATUS: "/v1/interactive_walkthrough/status/",
  INTERACTIVE_WALKTHROUGH_VIEW: "/v1/interactive_walkthrough/view/",

  INSTAGRAM_SEARCH: `/v1/p/instagram/search/`,
  INSTAGRAM_LIVE_SEARCH: (profile: string | undefined) => `/v1/p/instagram/live_search/${profile}/`,
  INSTAGRAM_COMPARE_POST_STATISTICS: `/v1/p/instagram/comparison_post/`,
  INSTAGRAM_COMPARE_PROFILE_STATISTICS: `/v1/p/instagram/comparison_profile/`,
  INSTAGRAM_COMPARE_PROFILE_PRESET: `/v1/p/instagram/comparison_profile_preset/`,
  INSTAGRAM_COMPARE_POST_PRESET: `/v1/p/instagram/comparison_post_preset/`,
  INSTAGRAM_COMPARE_POST_PRESET_EDIT: (presetId: number) => `/v1/p/instagram/comparison_post_preset/${presetId}/`,
  INSTAGRAM_COMPARE_PROFILE_PRESET_EDIT: (presetId: number) => `/v1/p/instagram/comparison_profile_preset/${presetId}/`,
  INSTAGRAM_PROFILE: (profile: string | undefined) => `/v1/p/instagram/${profile}/`,
  INSTAGRAM_HASHTAGS: (profile: string | undefined) => `/v1/p/instagram/${profile}/tags/`,
  INSTAGRAM_POSTS: `/v1/p/instagram/posts/`,
  INSTAGRAM_PROFILE_POSTS: (profile: string | undefined) => `/v1/p/instagram/${profile}/post/`,
  INSTAGRAM_POST_STATISTICS: (profile: string | undefined) => `/v1/p/instagram/${profile}/post/statistics/`,
  INSTAGRAM_PROFILE_POST_DETAIL: (profile: string | undefined, post_id: string | undefined) => `/v1/p/instagram/${profile}/post/${post_id}/`,
  INSTAGRAM_PROFILE_WIDGET: (profile: string | undefined) => `/v1/p/instagram/${profile}/profile_widget/`,
  INSTAGRAM_PROFILE_HISTORY: (profile: string | undefined) => `/v1/p/instagram/${profile}/profile_history/`,
  INSTAGRAM_POST_HISTORY: (profile: string | undefined, post_id: string | undefined) => `/v1/p/instagram/${profile}/post/${post_id}/history/`,
  INSTAGRAM_FAVOURITE: (profile: string | undefined) => `/v1/p/instagram/${profile}/favourite/`,

  TIKTOK: "/v1/p/tiktok/",
  TIKTOK_SEARCH: `/v1/p/tiktok/search/`,
  TIKTOK_LIVE_SEARCH: (profile: string | undefined) => `/v1/p/tiktok/live_search/${profile}/`,
  TIKTOK_COMPARE_POST_STATISTICS: `/v1/p/tiktok/comparison_post/`,
  TIKTOK_COMPARE_PROFILE_PRESET: `/v1/p/tiktok/comparison_profile_preset/`,
  TIKTOK_COMPARE_POST_PRESET: `/v1/p/tiktok/comparison_post_preset/`,
  TIKTOK_COMPARE_POST_PRESET_EDIT: (presetId: number) => `/v1/p/tiktok/comparison_post_preset/${presetId}/`,
  TIKTOK_COMPARE_PROFILE_PRESET_EDIT: (presetId: number) => `/v1/p/tiktok/comparison_profile_preset/${presetId}/`,
  TIKTOK_COMPARE_PROFILE_STATISTICS: `/v1/p/tiktok/comparison_profile/`,
  TIKTOK_PROFILE: (profile: string | undefined) => `/v1/p/tiktok/${profile}/`,
  TIKTOK_HASHTAGS: (profile: string | undefined) => `/v1/p/tiktok/${profile}/tags/`,
  TIKTOK_POSTS: `/v1/p/tiktok/posts/`,
  TIKTOK_PROFILE_POSTS: (profile: string | undefined) => `/v1/p/tiktok/${profile}/post/`,
  TIKTOK_POST_STATISTICS: (profile: string | undefined) => `/v1/p/tiktok/${profile}/post/statistics/`,
  TIKTOK_PROFILE_POST_DETAIL: (profile: string | undefined, post_id: string | undefined) => `/v1/p/tiktok/${profile}/post/${post_id}/`,
  TIKTOK_PROFILE_WIDGET: (profile: string | undefined) => `/v1/p/tiktok/${profile}/profile_widget/`,
  TIKTOK_PROFILE_HISTORY: (profile: string | undefined) => `/v1/p/tiktok/${profile}/profile_history/`,
  TIKTOK_POST_HISTORY: (profile: string | undefined, post_id: string | undefined) => `/v1/p/tiktok/${profile}/post/${post_id}/history/`,
  TIKTOK_FAVOURITE: (profile: string | undefined) => `/v1/p/tiktok/${profile}/favourite/`,

  ACTIVITY_LOG: (account_id: string | undefined) => `/v1/admin/subscriptions/admin/accounts/${account_id}/social_media_subscribe_log/`,
  SUBSCRIPTION_PLAN: (account_id: string | undefined) => `/v1/admin/subscriptions/admin/accounts/${account_id}/subscription/`,
  SUBSCRIPTION_SLOT_RESET: (account_id: string | undefined, subscription_id: number | undefined) =>
    `/v1/admin/subscriptions/admin/accounts/${account_id}/subscription/${subscription_id}/reset/`,
  SUBSCRIPTION_PLAN_DETAIL: (account_id: string | undefined, plan_id: number) =>
    `/v1/admin/subscriptions/admin/accounts/${account_id}/subscription/${plan_id}/`,
  SUBSCRIPTION_PLAN_PACKAGE: `/v1/admin/subscriptions/admin/packages/`,
  SUBSCRIPTION_ACCOUNT: `/v1/admin/subscriptions/admin/accounts/`,
  SUBSCRIPTION_ACCOUNT_DETAIL: (account_id: string | undefined) => `/v1/admin/subscriptions/admin/accounts/${account_id}/`,
  SUBSCRIPTION_ACCOUNT_EMAIL: (account_id: string | undefined) => `/v1/admin/subscriptions/admin/accounts/${account_id}/user/`,
  SUBSCRIPTION_ACCOUNT_PASSWORD: (account_id: string | undefined) => `/v1/admin/subscriptions/admin/accounts/${account_id}/password/update/`,
  SUBSCRIPTION_ACCOUNT_CREDIT: (account_id: string | undefined) => `/v1/admin/subscriptions/admin/accounts/${account_id}/credits/`,
  SUBSCRIPTION_ACCOUNT_TRANSACTION: `/v1/admin/subscriptions/admin/transactions/`,
  SUBSCRIPTION_ACCOUNT_TRANSACTION_DETAIL: (account_id: string | undefined) => `/v1/admin/subscriptions/admin/accounts/${account_id}/transactions/`,
  SUBSCRIPTION_ACCOUNT_TRACKING_STATUS: (account_id: string | undefined) =>
    `/v1/admin/subscriptions/admin/accounts/${account_id}/social_media_subscribe/`,
  CURRENT_SUBSCRIPTION: (account_id: string | undefined) => `/v1/admin/subscriptions/admin/accounts/${account_id}/subscription/current_subscription/`,
  NEXT_SUBSCRIPTION: (account_id: string | undefined) => `/v1/admin/subscriptions/admin/accounts/${account_id}/subscription/next_subscription/`,
};

export const HttpConsumer = HttpContext.Consumer;
