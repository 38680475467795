import React, { useEffect, useState } from "react";
/* components */
/* 3rd party lib */
import {
  Box,
  Grid,
  Link,
  styled,
  Stack,
  Button,
  TextField,
  Typography,
  FormControl,
  IconButton,
  useTheme,
  FormControlLabel,
  Checkbox,
  InputAdornment,
  lighten,
} from "@mui/material";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { /*CredentialResponse,  GoogleLogin, */ useGoogleLogin } from "@react-oauth/google";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";

/* Util */
import routes from "src/routes";
import useAuth from "src/hooks/use-auth";
import LogoLight from "src/images/logo_light.png";
import LogoDark from "src/images/logo_dark.png";
import GoogleLogo from "src/images/google.png";
import LoginImg from "src/images/login.png";
import { setTheme } from "src/slices/general";
import { RootState } from "src/store/root-reducer";
import useMediaQueries from "src/hooks/use-mediaqueries";
import { getTranslateString } from "src/utils/translate";
import { FORM_WARNING_REQUIRED, FORM_WARNING_VALID_EMAIL } from "src/constants/translate-keys/common";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import PageFooter from "src/components/common/layout/page-footer";
import axios from "axios";
import useHttp from "src/hooks/use-http";
import FacebookLoginButton from "src/components/authentication/facebook-login-button";

const StyledTextField = styled(TextField)(() => ({
  "& input": {
    overflow: "hidden",
    borderRadius: "8px",
    padding: "20px 8px",
  },
  "& .MuiInput-root": {
    overflow: "hidden",
    borderRadius: "8px",
  },
}));

interface LoginProps {}

type Props = LoginProps;

const schema = (t: any) =>
  Yup.object().shape({
    email: Yup.string().email(getTranslateString(t, FORM_WARNING_VALID_EMAIL)).required(getTranslateString(t, FORM_WARNING_REQUIRED)),
    password: Yup.string().required(getTranslateString(t, FORM_WARNING_REQUIRED)),
  });

const Login: React.FC<Props> = () => {
  /* ================================================== */
  /*  state */
  /* ================================================== */

  const { t } = useTranslation();
  const { smDown, mdDown } = useMediaQueries();
  const { login, socialMediaLogin } = useAuth();
  const location = useLocation();
  const { clickedPricing } = location.state || {};
  const { isAuthenticated } = useSelector((state: RootState) => state.auth);
  const bottomHeight = 60;
  const theme = useTheme();
  const { apiEndpoint } = useHttp();
  const { mode } = theme.palette;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [checked, setChecked] = useState(false);
  const [showSignUp, setShowSignUp] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const formik = useFormik({
    validateOnChange: false,
    validationSchema: schema(t),
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit: async values => {
      try {
        login(values.email, values.password);
      } catch (error) {
        console.error(error);
      }
    },
  });
  /* ================================================== */
  /*  method */
  /* ================================================== */

  const customLogin = useGoogleLogin({
    onSuccess: async (codeResponse: any) => {
      try {
        await axios
          .post(`${process.env.REACT_APP_BASE_URL}${apiEndpoint.GOOGLE_AUTH.substring(1)}`, {
            access_token: codeResponse.access_token,
          })
          .then(res => {
            if (res.data) {
              socialMediaLogin(res.data.access, res.data.refresh);
            }
          })
          .catch(err => console.error(err));
      } catch (err) {
        console.error(err);
      }
    },
    onError: () => console.log("Login Failed:"),
  });

  /* ================================================== */
  /*  useEffect */
  /* ================================================== */

  // log out function to log the user out of google and set the profile array to null

  useEffect(() => {
    if (localStorage.getItem("remember_me") === "true") {
      setChecked(true);
    } else {
      setChecked(false);
    }
  }, []);

  useEffect(() => {
    // redirect to home is authenticated
    if (isAuthenticated) {
      navigate(routes.ROOT, { state: { clickedPricing }, replace: true });
    }
  }, [clickedPricing, isAuthenticated, navigate]);

  /* ================================================== */
  /* ================================================== */
  return (
    <Box sx={{ "*": { boxSizing: "border-box" } }}>
      <Button
        onClick={() => {
          navigate(routes.ROOT);
        }}
        sx={{ position: "absolute", left: "16px", top: "16px" }}
      >
        <Stack direction="row" alignItems="center" spacing={0.5}>
          <img src={mode === "light" ? LogoLight : LogoDark} alt="logo" height="40px" />
        </Stack>
      </Button>
      <IconButton
        sx={{ color: theme.palette.text.secondary, ml: 1, position: "absolute", right: "16px", top: "16px" }}
        onClick={() => {
          if (mode === "dark") {
            dispatch(setTheme("light"));
          } else {
            dispatch(setTheme("dark"));
          }
        }}
      >
        {mode === "dark" ? <Brightness7Icon /> : <Brightness4Icon />}
      </IconButton>
      <Grid container sx={{ height: "100vh" }}>
        {!mdDown && (
          <Grid item xs={12} md={6} lg={7} xl={8}>
            <Stack sx={{ height: "100vh" }}>
              <Box sx={{ height: "calc(100% - 88px)", display: "grid", placeItems: "center" }}>
                <img alt="login_illustration" style={{ borderRadius: "8px" }} src={LoginImg} width="70%" />
              </Box>
              <Box sx={{ width: "100%", px: 3 }}>
                <PageFooter copyrightNextLine />
              </Box>
            </Stack>
          </Grid>
        )}
        <Grid
          item
          xs={12}
          md={6}
          lg={5}
          xl={4}
          sx={{
            display: "grid",
            placeItems: "center",
            background:
              mode === "light" ? (mdDown ? "white" : lighten((theme.palette.neutral as any)[100], 0.1)) : (theme.palette.neutral as any)[900],
          }}
        >
          <Box
            py={3}
            sx={{
              display: "grid",
              placeItems: "center",
              height: `calc(100% - ${bottomHeight}px)`,
              width: { xs: "90%", sm: "400px", md: "90%" },
            }}
          >
            <Box sx={{ transform: "translateY(-20px)" }}>
              <Stack justifyContent="center" alignItems={smDown ? "center" : "flex-start"} spacing={1} mb={3}>
                {smDown ? (
                  <>
                    <Typography variant="h4" color="text.primary" fontWeight={500}>
                      Welcome to goIntec
                    </Typography>
                  </>
                ) : (
                  <>
                    <Typography variant="h4" fontWeight={"bolder"} color="primary.main">
                      Hello,
                    </Typography>
                    <Typography variant="h4" color="text.primary" fontWeight={500}>
                      Welcome to goIntec
                    </Typography>
                  </>
                )}
              </Stack>

              <Stack spacing={2}>
                <Stack spacing={2}>
                  <Button variant="outlined" onClick={customLogin as any} sx={{ position: "relative", width: "100%" }}>
                    <Box sx={{ position: "absolute", height: "32px", width: "32px", left: "8px", top: "50%", transform: "translateY(-50%)" }}>
                      <img height="32px" width="32px" src={GoogleLogo} alt="google" />
                    </Box>
                    {/* <Box sx={{ width: "100%", display: "flex", justifyContent: "center", background: "red" }}>
                      <GoogleLogin onSuccess={responseMessage} onError={errorMessage} />
                    </Box> */}
                    <Typography variant="body1" color="text.secondary">
                      Continue with Google
                    </Typography>
                  </Button>
                  <FacebookLoginButton />
                </Stack>

                <Typography variant="body1" color="text.secondary">
                  OR
                </Typography>
                <form noValidate onSubmit={formik.handleSubmit}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <StyledTextField
                          hiddenLabel
                          variant="outlined"
                          name="email"
                          placeholder="Email"
                          value={formik.values.email}
                          onChange={formik.handleChange}
                          error={formik.errors.email ? true : false}
                          helperText={formik.errors.email}
                          autoComplete="current-password"
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <StyledTextField
                          variant="outlined"
                          hiddenLabel
                          name="password"
                          placeholder="Password"
                          value={formik.values.password}
                          onChange={formik.handleChange}
                          error={formik.errors.password ? true : false}
                          helperText={formik.errors.password}
                          InputProps={{
                            autoComplete: "current-password",
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  tabIndex={-1}
                                  sx={{ color: theme.palette.primary.main }}
                                  onClick={() => setShowPassword(!showPassword)}
                                  edge="end"
                                >
                                  {showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          type={showPassword ? "text" : "password"}
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                      <Stack direction="row" alignItems="center" justifyContent={"space-between"}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              size="small"
                              checked={checked}
                              onChange={e => {
                                setChecked(e.target.checked);
                                if (e.target.checked) {
                                  localStorage.setItem("remember_me", "true");
                                } else {
                                  localStorage.setItem("remember_me", "false");
                                }
                              }}
                            />
                          }
                          label={
                            <Typography variant="body1" color="text.primary">
                              Remember Me
                            </Typography>
                          }
                        />

                        <Link
                          onClick={() => navigate(routes.FORGOT_PASSWORD)}
                          // href={routes.SIGNUP}
                          variant="body1"
                          color="text.primary"
                          underline="hover"
                          sx={{ whiteSpace: "nowwrap", cursor: "pointer" }}
                        >
                          Forgot Password?
                        </Link>
                      </Stack>
                    </Grid>
                  </Grid>
                  <Box sx={{ mt: 2 }}>
                    <Button fullWidth size="large" disabled={formik.isSubmitting} type="submit" variant="contained">
                      SIGN IN
                    </Button>
                  </Box>

                  <Box sx={{ mt: 2, userSelect: "none" }}>
                    {showSignUp ? (
                      <Typography>
                        Already have an account?
                        <Link sx={{ cursor: "pointer" }} onClick={() => setShowSignUp(false)}>
                          Login Here
                        </Link>
                      </Typography>
                    ) : (
                      <Typography>
                        Don't have an account?&nbsp;
                        <Link href={routes.SIGNUP} sx={{ cursor: "pointer" }}>
                          Sign Up Here
                        </Link>
                      </Typography>
                    )}
                  </Box>
                </form>
              </Stack>
            </Box>
          </Box>
        </Grid>
      </Grid>
      {mdDown && <PageFooter />}
    </Box>
  );
};

export default Login;
