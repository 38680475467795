import React, { useEffect, useMemo, useRef, useState } from "react";
import moment from "moment";
import useHttp from "src/hooks/use-http";
import { useParams, useSearchParams } from "react-router-dom";
import { Box, Grid, Paper, Stack, Typography } from "@mui/material";
import { MRT_ColumnDef } from "material-react-table";
import { tiktokLineColors, TABLE_ID } from "src/utils/constants";
import OuterLayout from "src/components/common/layout/outer-layout";
import { getDynamicIndex } from "src/utils/general";
import DataTableList from "src/components/common/data-display/data-table-list";
import ProfilePostBanner from "src/components/profile/tiktok/profile-post-banner";
import { NUMBER_CELL_TRANSLATE } from "src/components/common/data-display/dnd-table";
import DiffCount from "src/components/common/data-display/diff-count";
import LineChart from "src/components/common/data-display/charts/line-chart";
import { ITikTokPostHistoryData, TTikTokPostHistory } from "src/types/tiktok";
import useBackground from "src/hooks/use-background";
import NotFound404 from "src/components/common/feedback/404";
import { RootState } from "src/store/root-reducer";
import { useSelector } from "react-redux";
import ExpiredOverlay from "src/components/common/data-display/expired-overlay";
import useSWR from "swr";
import { IFormDateFilter } from "src/types/common";
import { useFormik } from "formik";
import DateFilter from "src/components/common/inputs/date-filter";

interface LineChartData {
  yAxisData: number[];
}

interface ITikTokPostHistoryChartData {
  // view: LineChartData;
  like: LineChartData;
  comment: LineChartData;
  play: LineChartData;
  saved: LineChartData;
  share: LineChartData;
  xAxisData: string[];
}

interface InstaPostProps {}

type Props = InstaPostProps;

const InstaPostHistory: React.FC<Props> = () => {
  /* ================================================== */
  /*  state */
  /* ================================================== */
  const params = useParams();
  const { post_id, username } = params;
  const { apiEndpoint } = useHttp();
  const background = useBackground({});
  const dataTableListRef = useRef(null);
  const [searchParams] = useSearchParams();
  const queryStartDate = searchParams.get("start_min"); // e.g., for ?myParam=value
  const queryEndDate = searchParams.get("start_max"); // e.g., for ?myParam=value

  const [show404, setShow404] = useState(false);
  const { user } = useSelector((state: RootState) => state.auth);
  const [chartData, setChartData] = useState<ITikTokPostHistoryChartData | null>(null);
  const { dateFilter } = useSelector((state: RootState) => state.general);
  const [checkPagination, setCheckPagination] = useState({ currentPage: 0, rowsPerPage: 0 });

  // last 7 days
  const startDate = moment().subtract(30, "days").format("YYYY-MM-DD");
  const endDate = moment().format("YYYY-MM-DD");

  const formik = useFormik<IFormDateFilter>({
    initialValues: {
      start_min: queryStartDate ? queryStartDate : dateFilter.start_min ? dateFilter.start_min : startDate,
      start_max: queryEndDate ? queryEndDate : dateFilter.start_max ? dateFilter.start_max : endDate,
    },
    onSubmit: () => {},
  });

  // will have double api call, because data table uses its own api call
  const config = useMemo(() => {
    return {
      params: {
        date_min: moment(formik.values.start_min).format("YYYY-MM-DD"),
        date_max: moment(formik.values.start_max).format("YYYY-MM-DD"),
      },
    };
  }, [formik.values.start_min, formik.values.start_max]);

  const { data: chartApiData } = useSWR<TTikTokPostHistory>([apiEndpoint.TIKTOK_POST_HISTORY(username, post_id), config]);

  const columns: MRT_ColumnDef<{}>[] = useMemo(
    () => [
      {
        accessorKey: "no",
        header: "#",
        size: 0,
        muiTableHeadCellProps: {
          sx: {
            ...NUMBER_CELL_TRANSLATE,
          },
        },
        enablePinning: false,
        enableSorting: false,
        enableColumnActions: false,
        enableColumnFilter: false,
        enableColumnOrdering: false,
        enableColumnDragging: false,

        Cell: ({ row }) => {
          const { currentPage, rowsPerPage } = checkPagination;
          return (
            <Typography variant="body2" color="text.secondary" sx={{ textAlign: "center" }}>
              {getDynamicIndex(currentPage - 1, rowsPerPage, row.index + 1)}.
            </Typography>
          );
        },
      },
      {
        accessorKey: "date",
        header: "Date",
        size: 80,
        enableColumnFilter: false,
        Cell: ({ row }) => {
          const rowData = row.original as ITikTokPostHistoryData;
          return moment(rowData.date).format("DD/MM/YYYY");
        },
      },

      {
        accessorKey: "count_play",
        header: "Plays",
        size: 80,
        enableColumnFilter: false,
        Cell: ({ row }) => {
          const rowData = row.original as ITikTokPostHistoryData;
          return <DiffCount count={rowData.count_play} diff={rowData.diff_count_play} />;
        },
      },
      {
        accessorKey: "count_like",
        header: "Likes",
        size: 80,
        enableColumnFilter: false,
        Cell: ({ row }) => {
          const rowData = row.original as ITikTokPostHistoryData;
          return <DiffCount count={rowData.count_like} diff={rowData.diff_count_like} />;
        },
      },
      {
        accessorKey: "count_comment",
        header: "Comments",
        size: 80,
        enableColumnFilter: false,
        Cell: ({ row }) => {
          const rowData = row.original as ITikTokPostHistoryData;
          return <DiffCount count={rowData.count_comment} diff={rowData.diff_count_comment} />;
        },
      },
      {
        accessorKey: "count_saved",
        header: "Saved",
        size: 80,
        enableColumnFilter: false,
        Cell: ({ row }) => {
          const rowData = row.original as ITikTokPostHistoryData;
          return <DiffCount count={rowData.count_saved} diff={rowData.diff_count_saved} />;
        },
      },
      {
        accessorKey: "count_share",
        header: "Shares",
        size: 80,
        enableColumnFilter: false,
        Cell: ({ row }) => {
          const rowData = row.original as ITikTokPostHistoryData;
          return <DiffCount count={rowData.count_share} diff={rowData.diff_count_share} />;
        },
      },
      {
        accessorKey: "follower",
        header: "Followers",
        size: 80,
        enableColumnFilter: false,
        Cell: ({ row }) => {
          const rowData = row.original as ITikTokPostHistoryData;
          return <DiffCount count={rowData.follower} diff={rowData.diff_follower} />;
        },
      },
      {
        accessorKey: "engagement_rate",
        header: "Engagement Rate",
        size: 80,
        enableColumnFilter: false,
        Cell: ({ row }) => {
          const rowData = row.original as ITikTokPostHistoryData;
          // return <DiffCount count={rowData.engagement_rate} diff={rowData.engagement_rate} />;
          return rowData.engagement_rate;
        },
      },
    ],
    [checkPagination]
  );

  const listMetadata = {
    primary: { path: "date" },
    sortFields: [],
    fields: [
      {
        path: "count_view",
        label: "Views",
      },
      {
        path: "count_like",
        label: "Likes",
      },
      {
        path: "count_comment",
        label: "Comments",
      },
      {
        path: "count_play",
        label: "Play",
      },
      {
        path: "count_share",
        label: "Share",
      },
      {
        path: "count_saved",
        label: "Saved",
      },
      {
        path: "follower",
        label: "Followers",
      },
      {
        path: "engagement_rate",
        label: "Engagement Rate",
      },
    ],
  };

  /* ================================================== */
  /*  method */
  /* ================================================== */
  /* ================================================== */
  /*  useEffect */
  /* ================================================== */

  useEffect(() => {
    if (chartApiData) {
      let resultObj: ITikTokPostHistoryChartData = {
        like: { yAxisData: [] },
        comment: { yAxisData: [] },
        play: { yAxisData: [] },
        saved: { yAxisData: [] },
        share: { yAxisData: [] },
        xAxisData: [],
      };

      chartApiData.results
        .slice()
        .reverse()
        .forEach((child: ITikTokPostHistoryData) => {
          resultObj.like.yAxisData.push(child.count_like);
          resultObj.comment.yAxisData.push(child.count_comment);
          resultObj.play.yAxisData.push(child.count_play);
          resultObj.saved.yAxisData.push(child.count_saved);
          resultObj.share.yAxisData.push(child.count_share);
          resultObj.xAxisData.push(child.date);
        });

      setChartData(resultObj);
    }
  }, [chartApiData]);
  /* ================================================== */
  /* ================================================== */

  return (
    <OuterLayout maxWidth="xl">
      <Box sx={{ position: "relative" }}>
        {user && user.expired && <ExpiredOverlay />}
        {show404 ? (
          <NotFound404 post />
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <ProfilePostBanner setShow404={setShow404} />
            </Grid>
            <Grid item xs={12}>
              <Paper elevation={5} sx={{ width: "100%", background: background }}>
                <Stack p={1}>
                  {chartData && (
                    <Box sx={{ pb: 1 }}>
                      <Grid container spacing={1}>
                        {/* <Grid item xs={12} sm={2.4} md={3}>
                    <Paper elevation={5} sx={{ width: "100%" }}>
                      <Box className="widget__div" pb={0}>
                        <Typography variant="h6" fontWeight={500} color="text.secondary" sx={{ textAlign: "left", pl: 5 }}>
                          Views
                        </Typography>
                        {chartData && (
                          <LineChart
                            color={tiktokLineColors.total_view}
                            xAxisData={chartData.xAxisData.map(child => moment(child).format("DD MMM"))}
                            yAxisData={chartData.view.yAxisData}
                          />
                        )}
                      </Box>
                    </Paper>
                  </Grid> */}
                        <Grid item xs={12} sm={2.4}>
                          <Paper elevation={5} sx={{ width: "100%" }}>
                            <Box className="widget__div" pb={0}>
                              <Typography variant="h6" fontWeight={500} color="text.secondary" sx={{ textAlign: "left", pl: 5 }}>
                                Plays
                              </Typography>
                              {chartData && (
                                <LineChart
                                  color={tiktokLineColors.video}
                                  xAxisData={chartData.xAxisData.map(child => moment(child).format("DD MMM"))}
                                  yAxisData={chartData.play.yAxisData}
                                />
                              )}
                            </Box>
                          </Paper>
                        </Grid>
                        <Grid item xs={12} sm={2.4}>
                          <Paper elevation={5} sx={{ width: "100%" }}>
                            <Box className="widget__div" pb={0}>
                              <Typography variant="h6" fontWeight={500} color="text.secondary" sx={{ textAlign: "left", pl: 5 }}>
                                Likes
                              </Typography>
                              {chartData && (
                                <LineChart
                                  color={tiktokLineColors.total_like}
                                  xAxisData={chartData.xAxisData.map(child => moment(child).format("DD MMM"))}
                                  yAxisData={chartData.like.yAxisData}
                                />
                              )}
                            </Box>
                          </Paper>
                        </Grid>
                        <Grid item xs={12} sm={2.4}>
                          <Paper elevation={5} sx={{ width: "100%" }}>
                            <Box className="widget__div" pb={0}>
                              <Typography variant="h6" fontWeight={500} color="text.secondary" sx={{ textAlign: "left", pl: 5 }}>
                                Comments
                              </Typography>
                              {chartData && (
                                <LineChart
                                  color={tiktokLineColors.total_comment}
                                  xAxisData={chartData.xAxisData.map(child => moment(child).format("DD MMM"))}
                                  yAxisData={chartData.comment.yAxisData}
                                />
                              )}
                            </Box>
                          </Paper>
                        </Grid>
                        <Grid item xs={12} sm={2.4}>
                          <Paper elevation={5} sx={{ width: "100%" }}>
                            <Box className="widget__div" pb={0}>
                              <Typography variant="h6" fontWeight={500} color="text.secondary" sx={{ textAlign: "left", pl: 5 }}>
                                Saved
                              </Typography>
                              {chartData && (
                                <LineChart
                                  color={tiktokLineColors.total_saved}
                                  xAxisData={chartData.xAxisData.map(child => moment(child).format("DD MMM"))}
                                  yAxisData={chartData.saved.yAxisData}
                                />
                              )}
                            </Box>
                          </Paper>
                        </Grid>
                        <Grid item xs={12} sm={2.4}>
                          <Paper elevation={5} sx={{ width: "100%" }}>
                            <Box className="widget__div" pb={0}>
                              <Typography variant="h6" fontWeight={500} color="text.secondary" sx={{ textAlign: "left", pl: 5 }}>
                                Share
                              </Typography>
                              {chartData && (
                                <LineChart
                                  color={tiktokLineColors.total_share}
                                  xAxisData={chartData.xAxisData.map(child => moment(child).format("DD MMM"))}
                                  yAxisData={chartData.share.yAxisData}
                                />
                              )}
                            </Box>
                          </Paper>
                        </Grid>
                        <Grid item xs={12}>
                          <Paper elevation={5} sx={{ width: "100%" }}>
                            <Box sx={{ p: 1 }}>
                              <DateFilter formik={formik} />
                            </Box>
                          </Paper>
                        </Grid>
                      </Grid>
                    </Box>
                  )}
                  <Box sx={{ borderRadius: "8px", overflow: "hidden" }}>
                    <DataTableList
                      id={TABLE_ID.TIKTOK_PROFILE_POST_HISTORY}
                      columns={columns}
                      columnOrder={columns.map(c => c.accessorKey)}
                      setCheckPagination={setCheckPagination}
                      dataTableListRef={dataTableListRef}
                      listMetadata={listMetadata}
                      showGlobalFilter={false}
                      enableMobileList={false}
                      customFilters={{
                        date_min: moment(formik.values.start_min).format("YYYY-MM-DD"),
                        date_max: moment(formik.values.start_max).format("YYYY-MM-DD"),
                      }}
                      // rowAction={(rowData: IHashtagPostData) => window.open(rowData.insta_link, "_blank")}
                      // rowActions={[
                      //   {
                      //     icon: faAngleRight,
                      //     label: "More",
                      //     onClick: (rowData: TInstaData) => {
                      //       navigate(`${routes.INSTA_PROFILE}/${rowData.username}`);
                      //     },
                      //     tooltip: () => "More",
                      //   },
                      // ]}
                      search={{
                        key: "q",
                        label: "Search username",
                      }}
                      url={`${apiEndpoint.TIKTOK_POST_HISTORY(username, post_id)}`}
                    />
                  </Box>
                </Stack>
              </Paper>
            </Grid>
          </Grid>
        )}
      </Box>
    </OuterLayout>
  );
};

export default InstaPostHistory;
