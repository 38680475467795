import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getTranslateString } from "src/utils/translate";
import MobileDrawer from "src/components/common/dialog/mobile-drawer";
import { BUTTON_CLEAR_ALL, BUTTON_SUBMIT, SORT } from "src/constants/translate-keys/common";
import ArrowUpwardRoundedIcon from "@mui/icons-material/ArrowUpwardRounded";
import ArrowDownwardRoundedIcon from "@mui/icons-material/ArrowDownwardRounded";
import { Box, Button, Checkbox, FormControlLabel, FormGroup, List, ListItem, Stack, ToggleButton, ToggleButtonGroup } from "@mui/material";
import useMediaQueries from "src/hooks/use-mediaqueries";

interface SortField {
  key: string;
  label: string;
}

interface MobileSortDrawerProps {
  open: boolean;
  onDrawerClose: () => void;
  sort: Record<string, string> | null;
  sortFields: SortField[];
  onSort: (result: Record<string, string> | null) => void;
}

const MobileSortDrawer: React.FC<MobileSortDrawerProps> = ({ open, onDrawerClose, sort, sortFields, onSort }) => {
  const { t } = useTranslation();
  const { smDown } = useMediaQueries();
  const [result, setResult] = useState<Record<string, string> | null>(null);

  useEffect(() => {
    if (sort && open) {
      setResult(sort);
    }
  }, [sort, open]);

  const onClose = () => {
    onDrawerClose();
    setResult(null);
  };

  const handleCheckboxChange = (key: string, value: boolean) => {
    // const newResult: Record<string, string> = { ...result };

    // if (!value) {
    //   delete newResult[key];
    // } else {
    //   newResult[key] = "asc";
    // }

    // setResult(newResult);

    if (value) {
      // Set only the currently selected checkbox in the result
      setResult({ [key]: "desc" });
    } else {
      // If the checkbox is unchecked, remove it from the result
      setResult(null);
    }
  };

  const handleSortOrderChange = (key: string, order: string | null) => {
    if (order !== null) {
      if (!result?.[key]) return;
      setResult(state => ({ ...state, [key]: order }));
    }
  };

  const handleSortSubmit = () => {
    onSort(result);
    onClose();
  };

  return (
    <MobileDrawer open={open} header={getTranslateString(t, SORT)} onClose={onClose}>
      <Stack sx={{ height: smDown ? "auto" : "calc(100% - 60px)" }} justifyContent="space-between">
        <Box sx={{ overflow: "auto", maxHeight: smDown ? "calc(90vh - 150px)" : "100vh" }}>
          <List dense disablePadding>
            {sortFields.map(field => (
              <ListItem
                key={field.key}
                disablePadding
                secondaryAction={
                  result?.[field.key] && (
                    <ToggleButtonGroup
                      color="primary"
                      exclusive
                      value={result[field.key]}
                      onChange={(e, value) => handleSortOrderChange(field.key, value)}
                      disabled={result[field.key] ? false : true}
                      size="small"
                    >
                      <ToggleButton value="asc" aria-label="descending">
                        <ArrowUpwardRoundedIcon fontSize="small" />
                      </ToggleButton>
                      <ToggleButton value="desc" aria-label="ascending">
                        <ArrowDownwardRoundedIcon fontSize="small" />
                      </ToggleButton>
                    </ToggleButtonGroup>
                  )
                }
              >
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox checked={result?.[field.key] ? true : false} onChange={e => handleCheckboxChange(field.key, e.target.checked)} />
                    }
                    label={field.label}
                    componentsProps={{ typography: { fontSize: 14 } }}
                  />
                </FormGroup>
              </ListItem>
            ))}
          </List>
        </Box>

        <Stack direction="row" spacing={1} sx={{ pt: 1 }}>
          <Button
            sx={{ whiteSpace: "nowrap" }}
            onClick={() => {
              onClose();
              onSort(null);
              setResult(null);
            }}
            disabled={sort === null}
            variant="outlined"
          >
            {getTranslateString(t, BUTTON_CLEAR_ALL)}
          </Button>
          <Button onClick={handleSortSubmit} variant="contained" color="primary" size="small" fullWidth>
            {getTranslateString(t, BUTTON_SUBMIT)}
          </Button>
        </Stack>
      </Stack>
    </MobileDrawer>
  );
};

export default MobileSortDrawer;
