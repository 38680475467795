/* components */
import AlertDialog from "src/components/common/dialog/alert-dialog";
import OuterLayout from "src/components/common/layout/outer-layout";
import GoToTopButton from "src/components/common/inputs/go-to-top-button";
import DelaySubmitInput from "src/components/common/inputs/delay-submit-input";
import BalanceProgress from "src/components/common/data-display/credit-balance";
import ExpiredOverlay from "src/components/common/data-display/expired-overlay";
import TikTokProfileListing from "src/pages/listing/tiktok/tiktok-profile-listing";
import InstaProfileListing from "src/pages/listing/instagram/insta-profile-listing";
import SearchAccountDialog from "src/components/common/dialog/search-account-dialog";
import SubscriptionPlanDialog from "src/components/common/dialog/subscription-plan-dialog";
/* 3rd party lib */
import useSWR from "swr";
import moment from "moment";

import { toast } from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBook, faInfoCircle } from "@fortawesome/pro-regular-svg-icons";
import { Step } from "react-joyride";
import { faArrowUpArrowDown, faFilter, faGrid, faScaleBalanced, faTable, faUserTie } from "@fortawesome/pro-solid-svg-icons";
import { Badge, Box, Button, Grid, Link, Paper, Stack, ToggleButton, ToggleButtonGroup, Tooltip, Typography, useTheme } from "@mui/material";
/* Util */
import routes from "src/routes";
import Insta from "src/images/insta.png";
import Welcome from "src/images/welcome.png";
import DarkViewModeGif from "src/images/gifs/viewmode_dark.gif";
import LightViewModeGif from "src/images/gifs/viewmode_light.gif";
import LightTrackGif from "src/images/gifs/add_light.gif";
import DarkTrackGif from "src/images/gifs/add_dark.gif";
import useHttp from "src/hooks/use-http";
import { setMuiTab } from "src/slices/general";
import { setViewMode } from "src/slices/layout";
import { useTranslation } from "react-i18next";
import { RootState } from "src/store/root-reducer";
import Tiktok from "src/images/darkModeTikTok.png";
import { PROFILE_TAB_PANEL, TUTORIALS } from "src/utils/constants";
import useMediaQueries from "src/hooks/use-mediaqueries";
import { TSocialMedia, TUserSlot } from "src/types/common";
import { checkObjectNotEmpty } from "src/utils/general";
import { getTranslateString } from "src/utils/translate";
import { BUTTON_REMOVE, PROMPT_DELETE_CONFIRMATION, TOAST_ERROR_WENT_WRONG } from "src/constants/translate-keys/common";
import JoyrideComponent from "src/components/comparison/profiles/overview-joyride";
import InfluencerTypeFilter from "src/components/common/inputs/influencer-type-filter";
import MobileSortDrawer from "src/components/common/inputs/mobile-sort-drawer";

export type TViewMode = "table" | "grid";
export type THandleRemoveProfile = (socialMedia: TSocialMedia, username: string, callback: () => void) => Promise<void>;
export type TDeleteDialog = {
  open: boolean;
  username: string;
  socialMedia: TSocialMedia;
};
interface ListingPageProps {}

type Props = ListingPageProps;

const ListingPage: React.FC<Props> = () => {
  /* ================================================== */
  /*  state */
  /* ================================================== */

  const theme = useTheme();
  const { t } = useTranslation();
  const location = useLocation();
  const [instaSortDialog, setInstaSortDialog] = useState(false);
  const [tikTokSortDialog, setTikTokSortDialog] = useState(false);
  const { clickedPricing } = location.state || {};
  const { http, apiEndpoint } = useHttp();
  const { mdUp, mdDown, smDown } = useMediaQueries();
  const toggleBg = `${theme.palette.primary.main} !important`;
  const toggleText = `${(theme.palette.grey as any)[400]} !important`;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [instaSearch, setInstaSearch] = useState("");
  const [tikTokSearch, setTikTokSearch] = useState("");
  const instaProfileGridRef = useRef<any | null>(null);
  const instaDataTableListRef = useRef<any | null>(null);
  const tiktokProfileGridRef = useRef<any | null>(null);
  const tiktokDataTableListRef = useRef<any | null>(null);
  const [instaInfluencerType, setInstaInfluencerType] = useState("null");
  const [tikTokInfluencerType, setTikTokInfluencerType] = useState("null");
  const { viewMode } = useSelector((state: RootState) => state.layout);
  const { user } = useSelector((state: RootState) => state.auth);
  const [showSubscriptionDialog, setShowSubscriptionDialog] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState<TDeleteDialog>({ open: false, username: "", socialMedia: "instagram" });
  const [addAccountDialog, setAddAccountDialog] = useState<{ open: boolean; socialMedia: TSocialMedia }>({ open: false, socialMedia: "instagram" });
  const { data: userSlotData, mutate: userSlotMutate } = useSWR<TUserSlot>([apiEndpoint.USER_SLOT]);
  const walkthroughImageWidth = smDown ? "100%" : "600px";
  const [instaSort, setInstaSort] = useState<Record<string, string> | null>(null);
  const [tikTokSort, setTikTokSort] = useState<Record<string, string> | null>(null);

  const instaSortFields = [
    { key: "username", label: "Username" },
    { key: "influencer_type", label: "Type" },
    { key: "count_follower", label: "Followers" },
    { key: "median_engagement_rate", label: "Engagement Rate" },
    { key: "total_play", label: "Total Plays" },
    { key: "total_like", label: "Total Likes" },
    { key: "total_comment", label: "Total Comments" },
    { key: "count_post", label: "Posts" },
    { key: "count_following", label: "Following" },
    { key: "category_name", label: "Category" },
  ];
  const tikTokSortFields = [
    { key: "username", label: "Username" },
    { key: "influencer_type", label: "Type" },
    { key: "count_follower", label: "Followers" },
    { key: "median_engagement_rate", label: "Engagement Rate" },
    { key: "total_play", label: "Total Plays" },
    { key: "count_like", label: "Total Likes" },
    { key: "total_comment", label: "Total Comments" },
    { key: "count_video", label: "Videos" },
    { key: "total_saved", label: "Total Saved" },
    { key: "total_share", label: "Total Share" },
    { key: "count_following", label: "Following" },
  ];

  // instruction steps
  const [run, setRun] = useState(false);
  const [stepIndex, setStepIndex] = useState(0);

  /* create array */

  const steps: Step[] = [
    {
      target: "body",
      placement: "center",
      content: (
        <Stack spacing={2} width={walkthroughImageWidth} alignItems="center" justifyContent={"center"}>
          <Stack>
            <Typography variant="h6" fontWeight={600} color="primary.main">
              Ready to dive in?
            </Typography>
            <Typography>Let’s take a quick tour of all the key features.</Typography>
          </Stack>
          <img src={Welcome} alt="started" width={smDown ? "100%" : "60%"} />
        </Stack>
      ),
      styles: smDown
        ? {}
        : {
            options: {
              width: "auto",
            },
          },
    },
    {
      target: ".step-1",
      placement: "right",
      content: (
        <Stack spacing={2} width={walkthroughImageWidth}>
          <img src={theme.palette.mode === "light" ? LightTrackGif : DarkTrackGif} alt="Start Tracking" width="100%" />

          <Stack spacing={1}>
            <Typography variant="h6" fontWeight={600} color="primary.main" textAlign="left">
              Getting started
            </Typography>
            <Typography textAlign="left">Click here to quickly add new social profiles of interest and begin tracking.</Typography>
          </Stack>

          <Typography></Typography>
        </Stack>
      ),
      styles: smDown
        ? {}
        : {
            options: {
              width: "auto",
            },
          },
    },
    {
      target: ".step-2",
      content: (
        <Stack spacing={2} width={walkthroughImageWidth}>
          <img src={theme.palette.mode === "light" ? LightViewModeGif : DarkViewModeGif} alt="View Mode" width="100%" />

          <Stack spacing={1}>
            <Typography variant="h6" fontWeight={600} color="primary.main" textAlign="left">
              Okay, I’m in
            </Typography>
            <Typography textAlign="left">Click the respective tabs to toggle to your preferred layout for greater clarity.</Typography>
          </Stack>
        </Stack>
      ),
      styles: smDown
        ? {}
        : {
            options: {
              width: "auto",
            },
          },
    },
    {
      target: ".step-3",
      content: (
        <Stack spacing={1}>
          <Typography variant="h6" fontWeight={600} color="primary.main" textAlign="left">
            Optimise your slots
          </Typography>
          <Typography textAlign="left">
            Optimise your slots See the number of slots you have for tracking new profiles in your dashboard. Tip: You can add more slots by upgrading
            to a higher-tier plan.
          </Typography>
          <Typography variant="caption" fontStyle="italic" pt={1} textAlign="left">
            Tip: You can add more slots by upgrading to a higher-tier plan.
          </Typography>
        </Stack>
      ),
    },
    {
      target: ".step-4",
      content: (
        <Stack spacing={1}>
          <Typography variant="h6" fontWeight={600} color="primary.main" textAlign="left">
            Stay on top of your plan
          </Typography>
          <Typography textAlign="left">See when your current subscription expires here.</Typography>
          <Typography variant="caption" fontStyle="italic" pt={1} textAlign="left">
            Tip: You can set up the date range of your analysis for a fresh time comparison with the most up-to-date data.
          </Typography>
        </Stack>
      ),
    },
    {
      target: ".step-5",
      content: (
        <Stack spacing={1}>
          <Typography variant="h6" fontWeight={600} color="primary.main" textAlign="left">
            Profile vs. profile
          </Typography>
          <Typography textAlign="left">Click here to compare the profiles you have added directly and in greater detail. </Typography>
          <Typography variant="caption" fontStyle="italic" pt={1} textAlign="left">
            Tip: You can set up the date range of your analysis for a fresh time comparison with the most up-to-date data.
          </Typography>
        </Stack>
      ),
    },
    {
      target: "body",
      placement: "center",
      content: (
        <Stack spacing={1}>
          <Typography textAlign="left" variant="h6" fontWeight={600} color="primary.main">
            Set for more?
          </Typography>
          <Typography textAlign="left">Get started by adding a few profiles or pages now! </Typography>
        </Stack>
      ),
      locale: {
        last: "Complete",
      },
    },
  ];

  /* ================================================== */
  /*  method */
  /* ================================================== */

  // passing these all the way down to profile non exist in order to trigger form submit
  const mutate = () => {
    userSlotMutate();
    if (instaDataTableListRef.current) {
      instaDataTableListRef.current.refreshData();
    }
    if (tiktokDataTableListRef.current) {
      tiktokDataTableListRef.current.refreshData();
    }
    if (instaProfileGridRef.current) {
      instaProfileGridRef.current.refreshData();
    }
    if (tiktokProfileGridRef.current) {
      tiktokProfileGridRef.current.refreshData();
    }
  };

  const handleRemoveProfile = async (socialMedia: TSocialMedia, username: string, callback: () => void) => {
    try {
      await http.delete(socialMedia === "instagram" ? apiEndpoint.INSTAGRAM_PROFILE(username) : apiEndpoint.TIKTOK_PROFILE(username));
      toast.success("Profile Removed");

      callback();
      mutate();
    } catch (err) {
      console.error(err);
      toast.error(getTranslateString(t, TOAST_ERROR_WENT_WRONG));
    }
  };

  /* ================================================== */
  /*  useEffect */
  /* ================================================== */

  useEffect(() => {
    dispatch(setMuiTab(PROFILE_TAB_PANEL.OVERVIEW));
  }, [dispatch]);

  useEffect(() => {
    if (clickedPricing) {
      // if user has clicked pricing before loggin in, then bring user to the my account page
      navigate(`${routes.MY_ACCOUNT}#subscription`, { state: { clickedPricing } });
    }
  }, [navigate, clickedPricing]);

  useEffect(() => {
    if (user && !user.email_verified) {
      navigate(routes.VERIFY);
    }
  }, [user, navigate]);

  /* ================================================== */
  /* ================================================== */
  return (
    <>
      <JoyrideComponent
        run={run}
        setRun={setRun}
        stepIndex={stepIndex}
        steps={steps}
        setStepIndex={setStepIndex}
        tutorialSection={TUTORIALS.DASHBOARD}
      />
      {/* <TrackTutorialDialog
        open={tutorialModal}
        onClose={() => setTutorialModal(false)}
        onStart={() => {
          setRun(true);
          setStepIndex(0);
          setTutorialModal(false);
        }}
      >
        <Paper sx={{ mt: 2, p: 2 }}>test</Paper>
      </TrackTutorialDialog> */}
      <GoToTopButton />
      <OuterLayout maxWidth={"xl"}>
        <form id="profileForm">
          <Grid container spacing={2}>
            {/* <Grid item xs={12}>
              <Box py={2}>
                <Typography aria-labelledby="home__title" variant="h6" fontWeight="normal" color="text.secondary">
                  goIntec allows you to keep track of user statistics and compare posts across different platforms, brand owners <br /> and
                  influencers to get a better understanding of the traffic flow
                </Typography>
              </Box>
            </Grid> */}
            <Grid item xs={12}>
              <Stack direction={smDown ? "column" : "row"} alignItems="center" spacing={1}>
                <Paper elevation={10} sx={{ height: "100px", minWidth: smDown ? "100%" : mdDown ? "auto" : "340px" }}>
                  <Stack
                    sx={{ pl: smDown ? (user?.isAdmin ? 0 : 2) : 1, pr: smDown ? 0 : 2 }}
                    direction="row"
                    spacing={1}
                    alignItems="center"
                    height="100%"
                    // minWidth="300px"
                    justifyContent={smDown ? "center" : "flex-start"}
                  >
                    {user?.isAdmin ? (
                      <Box sx={{ px: smDown ? 0 : 2 }}>
                        <FontAwesomeIcon icon={faUserTie} color={theme.palette.primary.main} size="3x" />
                      </Box>
                    ) : (
                      <BalanceProgress
                        gauge
                        balance={
                          userSlotData &&
                          checkObjectNotEmpty(userSlotData) &&
                          checkObjectNotEmpty(userSlotData.subscription) &&
                          userSlotData.slot_available
                            ? userSlotData.slot_available
                            : undefined
                        }
                        total={
                          userSlotData &&
                          checkObjectNotEmpty(userSlotData) &&
                          checkObjectNotEmpty(userSlotData.subscription) &&
                          userSlotData.subscription.package.slot
                            ? userSlotData.subscription.package.slot
                            : undefined
                        }
                      />
                    )}
                    {user?.isAdmin ? (
                      <Stack alignItems="flex-start">
                        <Typography variant="body1" textAlign={"left"} color="text.secondary" whiteSpace="nowrap" fontWeight={600}>
                          You are currently
                        </Typography>
                        <Typography variant="body2" textAlign={"left"} color="text.secondary" whiteSpace="nowrap">
                          using an admin account
                        </Typography>
                      </Stack>
                    ) : (
                      <Stack alignItems="flex-start">
                        <Typography variant="body1" textAlign={"left"} color="text.secondary" whiteSpace="nowrap" fontWeight={600}>
                          Slots Available
                        </Typography>

                        <Typography className="step-4" variant="body2" textAlign={"left"} color="text.secondary" whiteSpace="nowrap">
                          active until&nbsp;
                          {userSlotData &&
                          Object.keys(userSlotData).length > 0 &&
                          checkObjectNotEmpty(userSlotData.subscription) &&
                          userSlotData.subscription.slot_reset_time
                            ? moment(userSlotData.subscription.end_time).format("DD MMM YYYY")
                            : "-"}
                        </Typography>

                        {/* <Typography variant="body1" textAlign={"left"} color="text.secondary" whiteSpace="nowrap" fontWeight={600}>
                          Changes Remaining
                        </Typography>
                        {userSlotData &&
                          Object.keys(userSlotData).length > 0 &&
                          checkObjectNotEmpty(userSlotData.subscription) &&
                          userSlotData.subscription.slot_reset_time && (
                            <Typography variant="body2" textAlign={"left"} color="text.secondary" whiteSpace="nowrap">
                              resetting on {moment(userSlotData.subscription.slot_reset_time).format("DD MMM YYYY")}
                            </Typography>
                          )} */}
                      </Stack>
                    )}
                  </Stack>
                </Paper>

                {/* <Stack alignItems="flex-start">{socialMedia && <SocialMediaDropdown />}</Stack> */}

                {/* <Paper elevation={0} sx={{ flexGrow: 1 }}>
                <DelaySubmitInput
                  value={search}
                  variant="outlined"
                  setValue={setSearch}
                  clearInput={search === ""}
                  placeholder={`Track ${socialMedia} username`}
                />
              </Paper> */}

                <Paper elevation={10} sx={{ height: "100px", width: "100%" }}>
                  <Stack
                    sx={{ pl: 2, pr: smDown ? 1 : 4 }}
                    direction="row"
                    spacing={1}
                    alignItems="center"
                    height="100%"
                    justifyContent={smDown ? "center" : "flex-end"}
                  >
                    <Button
                      className="step-5"
                      startIcon={<FontAwesomeIcon icon={faScaleBalanced} />}
                      size="large"
                      color="secondary"
                      variant="contained"
                      onClick={() => navigate(routes.COMPARISON_PROFILES)}
                      sx={{ width: smDown ? "100%" : "auto" }}
                    >
                      <Typography variant="body1" fontWeight={600} whiteSpace="nowrap">
                        Compare Profiles
                      </Typography>
                    </Button>

                    <Box className="step-2" sx={{ pr: smDown ? 1 : 0 }}>
                      <ToggleButtonGroup
                        color="primary"
                        value={viewMode}
                        exclusive
                        sx={{ height: "46px" }}
                        onChange={(_e, value) => {
                          if (value) {
                            dispatch(setViewMode(value));
                          }
                        }}
                        aria-label="Platform"
                      >
                        <ToggleButton
                          title="Table View"
                          value="table"
                          sx={{
                            background: viewMode === "table" ? toggleBg : "",
                            color: viewMode === "table" ? "white !important" : toggleText,
                          }}
                        >
                          <Stack direction="row" alignItems="center" spacing={1}>
                            {mdUp && (
                              <Typography
                                whiteSpace={"nowrap"}
                                textTransform={"capitalize"}
                                variant="body2"
                                color={viewMode === "table" ? "white !important" : toggleText}
                              >
                                Table View
                              </Typography>
                            )}
                            <FontAwesomeIcon icon={faTable} />
                          </Stack>
                        </ToggleButton>
                        <ToggleButton
                          title="Grid View"
                          value="grid"
                          sx={{
                            background: viewMode === "grid" ? toggleBg : "",
                            color: viewMode === "grid" ? "white !important" : toggleText,
                          }}
                        >
                          <Stack direction="row" alignItems="center" spacing={1}>
                            {mdUp && (
                              <Typography
                                whiteSpace={"nowrap"}
                                textTransform={"capitalize"}
                                variant="body2"
                                color={viewMode === "grid" ? "white !important" : toggleText}
                              >
                                Grid View
                              </Typography>
                            )}
                            <FontAwesomeIcon icon={faGrid} />
                          </Stack>
                        </ToggleButton>
                      </ToggleButtonGroup>
                    </Box>
                  </Stack>
                </Paper>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Paper elevation={10} sx={{ p: smDown ? 2 : 4, position: "relative" }}>
                {user && user.expired && <ExpiredOverlay />}
                <Stack spacing={smDown ? 5 : 10}>
                  <Stack spacing={smDown ? 1 : 2}>
                    {userSlotData && checkObjectNotEmpty(userSlotData.subscription) && (
                      <Stack sx={{ pb: smDown ? 1 : 0 }} alignItems="center" justifyContent="flex-start" direction="row" spacing={1}>
                        <Tooltip
                          title={
                            user?.isAdmin
                              ? `${userSlotData.subscription.package.title} has unlimited slots`
                              : `${userSlotData.subscription.package.title} has up to ${userSlotData.subscription.package.slot} slots`
                          }
                        >
                          <FontAwesomeIcon icon={faInfoCircle} size="lg" color={theme.palette.text.secondary} />
                        </Tooltip>
                        {user?.isAdmin ? (
                          <Typography variant="body1" color="text.secondary">
                            Have access to all profiles
                          </Typography>
                        ) : (
                          <Typography variant="body1" color="text.secondary">
                            Total: {userSlotData.subscription.package.slot} slots
                          </Typography>
                        )}
                      </Stack>
                    )}
                    <Stack
                      direction={smDown ? "column" : "row"}
                      alignItems={smDown ? "flex-start" : "center"}
                      justifyContent={"space-between"}
                      spacing={smDown ? 1 : 0}
                    >
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <img src={Insta} alt="insta" width="28px" height="28px" />
                        <Typography variant="h6" fontWeight={"normal"}>
                          Instagram {instaDataTableListRef.current && <>(Total: {instaDataTableListRef.current.count})</>}
                        </Typography>
                      </Stack>
                      <Stack direction="row" alignItems="center" spacing={1} sx={{ width: smDown ? "100%" : "auto" }}>
                        {(mdDown || viewMode === "grid") && (
                          <Button
                            size="small"
                            onClick={() => {
                              setInstaSortDialog(true);
                            }}
                            startIcon={<FontAwesomeIcon icon={faArrowUpArrowDown} size="lg" />}
                            sx={{ position: "relative" }}
                          >
                            <Badge
                              color="error"
                              variant="dot"
                              invisible={instaSort === null}
                              sx={{ position: "absolute", top: "8px", right: "8px" }}
                            />
                            <Typography fontWeight="bolder" variant="body2">
                              Sort
                            </Typography>
                          </Button>
                        )}

                        <InfluencerTypeFilter influencerType={instaInfluencerType} setInfluencerType={setInstaInfluencerType} />
                        <Box sx={{ width: smDown ? "100%" : mdDown ? "auto" : "250px" }}>
                          <DelaySubmitInput
                            value={instaSearch}
                            variant="outlined"
                            setValue={setInstaSearch}
                            clearInput={instaSearch === ""}
                            placeholder={smDown ? "Filter" : `Filter Instagram username`}
                            icon={<FontAwesomeIcon icon={faFilter} size="xs" />}
                          />
                        </Box>
                      </Stack>
                    </Stack>
                    <InstaProfileListing
                      id="listing_page"
                      sort={instaSort}
                      showTrackButton
                      setSearch={setInstaSearch}
                      search={instaSearch}
                      userSlotData={userSlotData}
                      influencerType={instaInfluencerType}
                      setAddAccountDialog={setAddAccountDialog}
                      instaProfileGridRef={instaProfileGridRef}
                      dataTableListRef={instaDataTableListRef}
                      setShowDeleteDialog={setShowDeleteDialog}
                    />
                  </Stack>
                  <Stack spacing={smDown ? 1 : 2}>
                    <Stack
                      direction={smDown ? "column" : "row"}
                      alignItems={smDown ? "flex-start" : "center"}
                      justifyContent={"space-between"}
                      spacing={smDown ? 1 : 0}
                    >
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <Box
                          sx={{
                            width: "28px",
                            height: "28px",
                            background: "white",
                            border: "0.5px solid #808080",
                            borderRadius: "50%",
                            display: "grid",
                            placeItems: "center",
                          }}
                        >
                          <img src={Tiktok} alt="insta" width="100%" height="100%" />
                        </Box>

                        <Typography variant="h6" fontWeight={"normal"}>
                          Tik Tok {tiktokDataTableListRef.current && <>(Total: {tiktokDataTableListRef.current.count})</>}
                        </Typography>
                      </Stack>

                      <Stack direction="row" alignItems="center" spacing={1} sx={{ width: smDown ? "100%" : "auto" }}>
                        {(mdDown || viewMode === "grid") && (
                          <Button
                            size="small"
                            onClick={() => {
                              setTikTokSortDialog(true);
                            }}
                            startIcon={<FontAwesomeIcon icon={faArrowUpArrowDown} size="lg" />}
                            sx={{ position: "relative" }}
                          >
                            <Badge
                              color="error"
                              variant="dot"
                              invisible={tikTokSort === null}
                              sx={{ position: "absolute", top: "8px", right: "8px" }}
                            />
                            <Typography fontWeight="bolder" variant="body2">
                              Sort
                            </Typography>
                          </Button>
                        )}

                        <InfluencerTypeFilter influencerType={tikTokInfluencerType} setInfluencerType={setTikTokInfluencerType} />

                        <Box sx={{ width: smDown ? "100%" : mdDown ? "auto" : "250px" }}>
                          <DelaySubmitInput
                            value={tikTokSearch}
                            variant="outlined"
                            setValue={setTikTokSearch}
                            clearInput={tikTokSearch === ""}
                            placeholder={smDown ? "Filter" : `Filter TikTok username`}
                            icon={<FontAwesomeIcon icon={faFilter} size="xs" />}
                          />
                        </Box>
                      </Stack>
                    </Stack>
                    <TikTokProfileListing
                      sort={tikTokSort}
                      showTrackButton
                      id="listing_page"
                      search={tikTokSearch}
                      setSearch={setTikTokSearch}
                      userSlotData={userSlotData}
                      influencerType={tikTokInfluencerType}
                      tiktokProfileGridRef={tiktokProfileGridRef}
                      dataTableListRef={tiktokDataTableListRef}
                      setAddAccountDialog={setAddAccountDialog}
                      setShowDeleteDialog={setShowDeleteDialog}
                    />
                  </Stack>
                </Stack>
              </Paper>
            </Grid>
            <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
              <Link
                sx={{ cursor: "pointer", userSelect: "none" }}
                onClick={() => {
                  setRun(true);
                  setStepIndex(0);
                }}
              >
                <Typography>
                  <FontAwesomeIcon icon={faBook} />
                  &nbsp;Need help? View all our key features with a guided walkthrough here.
                </Typography>
              </Link>
            </Grid>
          </Grid>
        </form>
      </OuterLayout>
      <SearchAccountDialog
        mutate={mutate}
        userSlotData={userSlotData}
        userSlotMutate={userSlotMutate}
        open={addAccountDialog.open}
        socialMedia={addAccountDialog.socialMedia}
        onClose={() => setAddAccountDialog({ open: false, socialMedia: "instagram" })}
        filterSearch={addAccountDialog.socialMedia === "instagram" ? instaSearch : tikTokSearch}
      />

      <AlertDialog
        open={showDeleteDialog.open}
        onClose={() => setShowDeleteDialog({ open: false, username: "", socialMedia: "instagram" })}
        handleConfirm={() =>
          handleRemoveProfile(showDeleteDialog.socialMedia, showDeleteDialog.username, () => {
            setShowDeleteDialog({ open: false, username: "", socialMedia: showDeleteDialog.socialMedia });
          })
        }
        title={getTranslateString(t, PROMPT_DELETE_CONFIRMATION)}
        confirmText={getTranslateString(t, BUTTON_REMOVE)}
        danger
      >
        <Typography variant="body1" py={2}>
          Are you sure you want to remove this profile?
        </Typography>
      </AlertDialog>

      <SubscriptionPlanDialog open={showSubscriptionDialog} onClose={() => setShowSubscriptionDialog(false)} />

      <MobileSortDrawer
        open={instaSortDialog}
        sort={instaSort}
        sortFields={instaSortFields}
        onSort={key => {
          if (key) {
            setInstaSort(Object.keys(key).length === 0 ? null : key);
          } else {
            setInstaSort(null);
          }
        }}
        onDrawerClose={() => setInstaSortDialog(false)}
      />
      <MobileSortDrawer
        open={tikTokSortDialog}
        sort={tikTokSort}
        sortFields={tikTokSortFields}
        onSort={key => {
          if (key) {
            setTikTokSort(Object.keys(key).length === 0 ? null : key);
          } else {
            setTikTokSort(null);
          }
        }}
        onDrawerClose={() => setTikTokSortDialog(false)}
      />
    </>
  );
};

export default ListingPage;
