import React, { useEffect, useState } from "react";
/* components */
/* 3rd party lib */
import moment from "moment";
import { Box, Link, Skeleton, Stack, Theme, Typography } from "@mui/material";
import { faArrowsRotate, faImageSlash, faUserPlus } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
/* Util */
import routes from "src/routes";
import { faTrash } from "@fortawesome/pro-solid-svg-icons";
import { useSelector } from "react-redux";
import { RootState } from "src/store/root-reducer";
import { ICompareTikTokProfile } from "src/types/tiktok";
import { ICompareInstaProfile } from "src/types/instagram";
import { rainbowBarColors } from "src/components/common/data-display/charts/bar-chart";
import { useComparisonProfileContext } from "src/contexts/comparison-profile-context";
import { vibratingAnimation } from "src/utils/constants";

interface ProfileSelectionProps {
  index: number;
  data: ICompareInstaProfile | ICompareTikTokProfile | null | undefined;
  onDelete: (index: number) => void;
}

type Props = ProfileSelectionProps;

const ProfileSelection: React.FC<Props> = ({ data, index, onDelete }) => {
  /* ================================================== */
  /*  state */
  /* ================================================== */

  const [imageError, setImageError] = useState<{ [username: string]: boolean }>({});
  const { socialMedia } = useSelector((state: RootState) => state.general);
  const { theme, formik, tableDirection, setAddMoreDialog, setProfileDialog, profileUsernames, triggerVibration } = useComparisonProfileContext();
  const trackIsAfterMax = data && moment(data.profile.track_since).isAfter(moment(formik.values.start_max));
  /* ================================================== */
  /*  method */
  /* ================================================== */
  function onError(e: any, username: string) {
    setImageError({ [username]: true });
  }

  /* ================================================== */
  /*  useEffect */
  /* ================================================== */

  const { mode } = theme.palette;
  const imageBackground = data && imageError[data.profile.username] ? "#ff6b6bff" : "black";
  const noDataHoverStyle = !data
    ? {
        cursor: "pointer",
        background: `${(theme.palette.neutral as any)[mode === "dark" ? 600 : 800]} !important`,
        svg: {
          color: "white",
        },
      }
    : null;

  const size = tableDirection === "horizontal" ? "36px" : profileUsernames.length > 6 ? "130px" : "120px";

  let animation = triggerVibration === "select" ? vibratingAnimation : {};

  useEffect(() => {
    setImageError({});
  }, [data]);

  /* ================================================== */
  /* ================================================== */
  return (
    <Box
      sx={{
        ...animation,
        position: "relative",
        ".deleteButton": {
          display: "none",
        },
        "& .userCircle": {
          background: data ? imageBackground : (theme.palette.neutral as any)[theme.palette.mode === "light" ? 100 : 800],
        },
        "&:hover": {
          ".deleteButton": {
            display: "grid",
          },
          "& .userCircle": {
            // transition: "all 0.5s ease",
            // backgroundColor: (theme.palette.neutral as any)[600],
            // svg: {
            //   color: "white",
            // },
            // transform: data ? "translateY(-5px)" : "translateY(0)",
            background: `${data ? imageBackground : (theme.palette.neutral as any)[theme.palette.mode === "light" ? 600 : 700]} !important`,
          },
        },
      }}
      className="center"
      py={2}
    >
      <Stack
        direction={tableDirection === "horizontal" ? "row" : "column"}
        alignItems="center"
        spacing={tableDirection === "horizontal" ? 1.5 : 1}
        justifyContent={tableDirection === "horizontal" ? "flex-start" : "center"}
        className="step-1 active-step4"
      >
        {data === undefined ? (
          <Skeleton variant="circular" width={size} height={size} />
        ) : (
          <Stack direction="row" alignItems="center" spacing={0.5}>
            {tableDirection === "horizontal" && <Typography variant="body2">{index + 1}.</Typography>}
            <Box sx={{ position: "relative", width: size, height: size }}>
              <Box
                className="userCircle"
                onClick={() => {
                  !data && setAddMoreDialog(true);
                }}
                sx={{
                  borderRadius: "50%",
                  overflow: "hidden",
                  border: "1px solid #dcdcdc",
                  width: size,
                  height: size,
                  display: "grid",
                  placeItems: "center",
                  transition: "all 0.5s ease",

                  "&:hover": {
                    ...noDataHoverStyle,
                    " .select__modal": {
                      display: "grid",
                    },
                    ".deleteButton": {
                      display: "grid",
                    },
                  },
                }}
              >
                {data && (
                  <Box
                    className="select__modal"
                    sx={{
                      zIndex: 2,
                      top: 1,
                      left: 1,
                      width: size,
                      height: size,
                      display: "none",
                      position: "absolute",
                      borderRadius: "50%",
                      placeItems: "center",
                      background: "rgba(0, 0, 0, 0.408)",
                    }}
                  >
                    <Stack
                      direction="row"
                      spacing={1}
                      sx={
                        tableDirection === "horizontal"
                          ? {
                              background: (theme.palette.neutral as any)[mode === "dark" ? 800 : 500],
                              padding: "4px 8px",
                              borderRadius: "8px",
                              transform: "translateX(14px)",
                            }
                          : {}
                      }
                    >
                      <RefreshButton index={index} setProfileDialog={setProfileDialog} theme={theme} />
                      <DeleteButton data={data} index={index} onDelete={onDelete} theme={theme} />
                    </Stack>
                  </Box>
                )}
                <Box
                  sx={{
                    overflow: "hidden",
                    width: "100%",
                    height: "100%",
                    display: "grid",
                    placeItems: "center",
                  }}
                >
                  {data ? (
                    <>
                      {imageError[data.profile.username] ? (
                        <FontAwesomeIcon icon={faImageSlash} size={tableDirection === "horizontal" ? "lg" : "3x"} color="#ffffff" />
                      ) : (
                        <img
                          alt={data.profile.downloaded_image}
                          src={data.profile.downloaded_image}
                          style={{ width: "100%", height: "100%", objectFit: "cover" }}
                          onError={e => onError(e, data.profile.username)}
                        />
                      )}
                    </>
                  ) : (
                    <FontAwesomeIcon icon={faUserPlus} size="3x" />
                  )}
                </Box>
              </Box>
            </Box>
          </Stack>
        )}

        {data && (
          <>
            <Stack
              sx={{ width: "100%", pt: tableDirection === "horizontal" ? "6px" : 0 }}
              alignItems={tableDirection === "horizontal" ? "flex-start" : "center"}
              textAlign={tableDirection === "horizontal" ? "left" : "center"}
            >
              <Typography
                lineHeight={tableDirection === "horizontal" ? "18px" : "initial"}
                variant={profileUsernames.length > 8 ? "body2" : "body1"}
                sx={tableDirection === "horizontal" ? { width: "150px", whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" } : {}}
              >
                <Box
                  sx={{
                    display: "inline-block",
                    width: "10px",
                    height: "10px",
                    borderRadius: "50%",
                    background: rainbowBarColors[index % rainbowBarColors.length],
                  }}
                />
                &nbsp;
                {data.profile.display_name}
              </Typography>
              <Link
                onClick={e => {
                  e.stopPropagation();
                  window.open(
                    `${socialMedia === "instagram" ? routes.INSTA_PROFILE : routes.TIKTOK_PROFILE}/${data.profile.username}?start_min=${moment(
                      formik.values.start_min
                    ).format("YYYY-MM-DD")}&start_max=${moment(formik.values.start_max).format("YYYY-MM-DD")}`,
                    "_blank"
                  );
                }}
                href={`${socialMedia === "instagram" ? routes.INSTA_PROFILE : routes.TIKTOK_PROFILE}/${data.profile.username}?start_min=${moment(
                  formik.values.start_min
                ).format("YYYY-MM-DD")}&start_max=${moment(formik.values.start_max).format("YYYY-MM-DD")}`}
                rel="noopener noreferrer"
                target="_blank"
                variant="caption"
                color="primary.main"
                underline="always"
                sx={{ width: "100%", whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}
              >
                @{data.profile.username}
              </Link>
            </Stack>
            {trackIsAfterMax && (
              <Stack alignItems="center" sx={{ position: "absolute", bottom: 0 }}>
                <Typography variant="caption" color="text.secondary" whiteSpace="nowrap">
                  Tracked since {moment(data.profile.track_since).format("DD MMM YY")}
                </Typography>
              </Stack>
            )}
          </>
        )}
      </Stack>

      {/* {data && (
        <Box
          className="deleteButton"
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
            display: "grid",
            placeItems: "center",
            width: "30px",
            height: "30px",
            border: "1px solid #dcdcdc",
            borderRadius: "50%",
            background: "white",
            transition: "all 0.5s ease",
            "&:hover": {
              svg: {
                color: "white !important",
              },
              background: theme.palette.error.main,
            },
            "&:active": {
              transition: "none",
              svg: {
                color: `${theme.palette.error.main} !important`,
              },
              background: "white",
            },
          }}
          onClick={e => {
            e.stopPropagation();
            onDelete(index);
          }}
        >
          <FontAwesomeIcon icon={faTrash} size="lg" style={{ color: theme.palette.error.main }} />
        </Box>
      )} */}
    </Box>
  );
};

export default ProfileSelection;

const DeleteButton = ({
  data,
  theme,
  onDelete,
  index,
}: {
  data: ICompareInstaProfile | ICompareTikTokProfile | null | undefined;
  theme: Theme;
  onDelete: (index: number) => void;
  index: number;
}) => {
  return (
    <>
      {data && (
        <Box
          className="deleteButton"
          sx={{
            // position: "absolute",
            // top: 0,
            // right: 0,
            display: "grid",
            placeItems: "center",
            cursor: "pointer",
            width: "30px",
            height: "30px",
            border: "1px solid #dcdcdc",
            borderRadius: "50%",
            background: "white",
            transition: "all 0.5s ease",
            "&:hover": {
              svg: {
                color: "white !important",
              },
              background: theme.palette.error.main,
            },
            "&:active": {
              transition: "none",
              svg: {
                color: `${theme.palette.error.main} !important`,
              },
              background: "white",
            },
          }}
          onClick={e => {
            e.stopPropagation();
            onDelete(index);
          }}
        >
          <FontAwesomeIcon icon={faTrash} size="lg" style={{ color: theme.palette.error.main }} />
        </Box>
      )}
    </>
  );
};

const RefreshButton = ({
  theme,
  index,
  setProfileDialog,
}: {
  theme: Theme;
  index: number;
  setProfileDialog: React.Dispatch<
    React.SetStateAction<{
      index: number;
      open: boolean;
    }>
  >;
}) => {
  return (
    <>
      <Box
        className="deleteButton"
        sx={{
          // position: "absolute",
          // top: 0,
          // right: 0,
          display: "grid",
          placeItems: "center",
          cursor: "pointer",
          width: "30px",
          height: "30px",
          border: "1px solid #dcdcdc",
          borderRadius: "50%",
          background: "white",
          transition: "all 0.5s ease",
          "&:hover": {
            svg: {
              color: "white !important",
            },
            background: theme.palette.primary.main,
          },
          "&:active": {
            transition: "none",
            svg: {
              color: `${theme.palette.primary.main} !important`,
            },
            background: "white",
          },
        }}
        onClick={e => {
          e.stopPropagation();
          setProfileDialog({ index: index, open: true });
        }}
      >
        <FontAwesomeIcon icon={faArrowsRotate} size="lg" style={{ color: theme.palette.primary.main }} />
      </Box>
    </>
  );
};
