import React, { useEffect, useMemo, useState } from "react";
/* components */
import PostSelection from "src/components/comparison/posts/post-selection";
import ComparisonTableComponent from "src/components/comparison/posts/comparison-table-component";

/* 3rd party lib */
import moment from "moment";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { Box, Dialog, DialogContent, DialogTitle, IconButton, Stack, Typography } from "@mui/material";

/* Util */
import { ICompareTikTokPostJsonData, ICompareTikTokPost } from "src/types/tiktok";
import { useComparisonPostContext } from "src/contexts/comparison-post-context";
import { checkObjectNotEmpty } from "src/utils/general";

interface ComparisonTableProps {
  postData?: ICompareTikTokPostJsonData;
  onDeleteProfile: (index: number) => void;
}

type Props = ComparisonTableProps;

const ComparisonTable: React.FC<Props> = ({ postData, onDeleteProfile }) => {
  /* ================================================== */
  /*  state */
  /* ================================================== */

  const { formik, postIds, tableDirection, tableHeight, openFullScreenDialog, setOpenFullScreenDialog } = useComparisonPostContext();

  const contentColumnWidth = `${(100 - 216) / postIds.length}%`;
  const [largestNumDict, setLargestNumDict] = useState<{ [key: string]: number } | {}>({});

  const columnDefinition = useMemo(
    () => [
      {
        key: "post",
        label: "",
        cellStyle: {
          width: tableDirection === "horizontal" ? "220px" : contentColumnWidth,
          minWidth: tableDirection === "horizontal" ? "220px" : contentColumnWidth,
          textAlign: tableDirection === "horizontal" ? "left" : "center",
        },
        content: (data: ICompareTikTokPost | null | undefined, index: number) => {
          return (
            <Stack
              justifyContent={tableDirection === "horizontal" ? "center" : postData && Object.keys(postData).length === 0 ? "center" : "flex-start"}
              alignItems={tableDirection === "horizontal" ? "flex-start" : "center"}
              sx={{
                width: tableDirection === "horizontal" ? "150px" : "auto",
                height: tableDirection === "horizontal" ? "30px" : `calc(100% - ${postIds.length > 6 ? 30 : 30}px)`,
              }}
            >
              <PostSelection formik={formik} onDelete={onDeleteProfile} data={data} index={index} />
            </Stack>
          );
        },
      },
      {
        key: "count_play",
        label:
          tableDirection === "horizontal" ? (
            <Stack>
              <Box>Total</Box>Plays
            </Stack>
          ) : (
            "Total Plays"
          ),
        cellStyle: {
          width: contentColumnWidth,
          textAlign: "center",
        },
      },
      {
        key: "count_like",
        label:
          tableDirection === "horizontal" ? (
            <Stack>
              <Box>Total</Box>Likes
            </Stack>
          ) : (
            "Total Likes"
          ),
        cellStyle: {
          width: contentColumnWidth,
          textAlign: "center",
        },
      },
      {
        key: "count_comment",
        label:
          tableDirection === "horizontal" ? (
            <Stack>
              <Box>Total</Box>Comments
            </Stack>
          ) : (
            "Total Comments"
          ),
        cellStyle: {
          width: contentColumnWidth,
          textAlign: "center",
        },
      },
      {
        key: "count_saved",
        label:
          tableDirection === "horizontal" ? (
            <Stack>
              <Box>Total</Box>Saved
            </Stack>
          ) : (
            "Total Saved"
          ),
        cellStyle: {
          width: contentColumnWidth,
          textAlign: "center",
        },
      },
      {
        key: "count_share",
        label:
          tableDirection === "horizontal" ? (
            <Stack>
              <Box>Total</Box>Shares
            </Stack>
          ) : (
            "Total Shares"
          ),
        cellStyle: {
          width: contentColumnWidth,
          textAlign: "center",
        },
      },
      {
        key: "engagement_rate",
        label:
          tableDirection === "horizontal" ? (
            <Stack>
              <Box>Engagement</Box>Rate
            </Stack>
          ) : (
            "Engagement Rate"
          ),
        cellStyle: {
          width: contentColumnWidth,
          textAlign: "center",
        },
      },

      {
        key: "date_range",
        label:
          tableDirection === "horizontal" ? (
            <Stack>
              <Box>Tracking</Box>Period
            </Stack>
          ) : (
            "Tracking Period"
          ),
        cellStyle: {
          width: contentColumnWidth,
          textAlign: "center",
          minWidth: tableDirection === "horizontal" ? "80px" : "160px",
          lineHeight: "16px",
        },
        content: (data: ICompareTikTokPost | null | undefined) => {
          return (
            <Typography variant="caption" sx={{ fontSize: "14px", lineHeight: tableDirection === "horizontal" ? "0px" : "initial" }}>
              {data && "date_first" in data && "date" in data.date_first && (
                <>
                  {(!moment(formik.values.start_min).isSame(moment(data.date_first.date), "day") ||
                    !moment(formik.values.start_max).isSame(moment(data.date_last.date), "day")) && (
                    <>
                      {/* if its greater than or equal to 1 that means first date exist */}
                      {data.date_first.date && moment(data.date_first.date).format("DD MMM YY")}
                      {data.date_last.date && (
                        <>
                          &nbsp;-&nbsp;{tableDirection === "horizontal" && <br style={{ lineHeight: "6px" }} />}
                          {moment(data.date_last.date).format("DD MMM YY")}
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </Typography>
          );
        },
      },
    ],
    [postData, postIds, tableDirection, formik, contentColumnWidth, onDeleteProfile]
  );
  /* ================================================== */
  /*  method */
  /* ================================================== */
  /* ================================================== */
  /*  useEffect */
  /* ================================================== */

  useEffect(() => {
    let tmpDict = columnDefinition.reduce((acc, col) => {
      const largestNum = postIds.reduce((max, post_id) => {
        const postObj: any = postData && checkObjectNotEmpty(postData) && postData.results[post_id];

        if (postObj && postObj.date_last[col.key] && (postObj.date_last as any)[col.key] > max) {
          return (postObj.date_last as any)[col.key];
        }

        if (col.key === "engagement_rate") {
          if (postObj && postObj.post.engagement_stats[col.key] > max) {
            return postObj.post.engagement_stats[col.key];
          }
        }

        return max;
      }, 0);
      (acc as any)[col.key] = largestNum;
      return acc;
    }, {});

    setLargestNumDict(tmpDict);
  }, [columnDefinition, postData, postIds]);

  // useEffect(() => {
  //   if (!(checkArrayExist(postIds) && presetData && presetData.length > 0)) {
  //     setCurrentActive({ index: -1, preset: null });
  //   }
  // }, [checkArrayExist, postIds, presetData, setCurrentActive]);

  /* ================================================== */
  /* ================================================== */
  return (
    <>
      {openFullScreenDialog ? (
        <Dialog fullScreen fullWidth={true} open={openFullScreenDialog} onClose={() => setOpenFullScreenDialog(false)}>
          <DialogTitle sx={{ p: 2 }}>
            <Stack direction="row" justifyContent={"space-between"} alignItems="center" width="100%">
              <Stack direction="row" alignItems="center" spacing={1.5}>
                <Typography variant="inherit">Full Screen</Typography>
              </Stack>
              <IconButton onClick={() => setOpenFullScreenDialog(false)}>
                <CloseRoundedIcon fontSize="small" />
              </IconButton>
            </Stack>
          </DialogTitle>
          <DialogContent>
            <Box sx={{ pt: 1 }}>
              <ComparisonTableComponent
                tableHeight={tableHeight}
                postData={postData}
                columnDefinition={columnDefinition}
                largestNumDict={largestNumDict}
              />
            </Box>
          </DialogContent>
        </Dialog>
      ) : (
        <ComparisonTableComponent tableHeight={tableHeight} postData={postData} columnDefinition={columnDefinition} largestNumDict={largestNumDict} />
      )}
    </>
  );
};

export default ComparisonTable;
