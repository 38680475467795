/* components */
/* 3rd party lib */
import * as Yup from "yup";
import React, { useEffect, useRef, useState } from "react";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { Stack, Dialog, DialogTitle, IconButton, Typography, DialogContent, Paper, Box, useTheme, Button, Link } from "@mui/material";

/* Util */
import Insta from "src/images/insta.png";
import Search from "src/images/search.png";
import useMediaQueries from "src/hooks/use-mediaqueries";
import Tiktok from "src/images/darkModeTikTok.png";
import { IProfileSearchData, TSocialMedia, TUserSlot } from "src/types/common";
import { useFormik } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faCircleInfo } from "@fortawesome/pro-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { getTranslateString } from "src/utils/translate";
import { FORM_WARNING_REQUIRED } from "src/constants/translate-keys/common";
import useHttp from "src/hooks/use-http";
import AlertDialog from "src/components/common/dialog/alert-dialog";
import { KeyedMutator } from "swr";
import { checkObjectNotEmpty } from "src/utils/general";
import { toast } from "react-hot-toast";
import { RootState } from "src/store/root-reducer";
import { useSelector } from "react-redux";
import TrackInput from "src/components/comparison/profiles/track-input";

const schema = (t: any) =>
  Yup.object().shape({
    search: Yup.string().required(getTranslateString(t, FORM_WARNING_REQUIRED)),
  });

interface SearchAccountDialogProps {
  open: boolean;
  filterSearch: string; //this is to auto fill the search bar if filter exist
  mutate: () => void;
  onClose: () => void;
  socialMedia: TSocialMedia;
  userSlotData: TUserSlot | undefined;
  userSlotMutate: KeyedMutator<TUserSlot>;
}

type Props = SearchAccountDialogProps;

const SearchAccountDialog: React.FC<Props> = ({ filterSearch, open, onClose, mutate, socialMedia, userSlotData, userSlotMutate }) => {
  /* ================================================== */
  /*  state */
  /* ================================================== */
  const { t } = useTranslation();
  const { smDown } = useMediaQueries();
  const theme = useTheme();
  const inputRef = useRef<any | null>(null);
  const { http, apiEndpoint } = useHttp();
  const { user } = useSelector((state: RootState) => state.auth);
  const [prevUsername, setPrevUsername] = useState<string | null>(null);
  const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false);
  const [liveSearchProfile, setLiveSearchProfile] = useState<IProfileSearchData | null>(null);

  const [searchLoading, setSearchLoading] = useState(false);
  const [trackLoading, setTrackLoading] = useState(false);
  const [error, setError] = useState({ show: false, message: "" });

  const formik = useFormik<{ search: string }>({
    enableReinitialize: true,
    validateOnChange: false,
    validationSchema: schema(t),
    initialValues: {
      search: filterSearch ?? "",
    },
    onSubmit: async values => {
      setSearchLoading(true);

      try {
        let apiUrl = socialMedia === "instagram" ? apiEndpoint.INSTAGRAM_LIVE_SEARCH(values.search) : apiEndpoint.TIKTOK_LIVE_SEARCH(values.search);
        const { data }: { data: IProfileSearchData } = await http.get(apiUrl, {});

        setLiveSearchProfile(data);
        setSearchLoading(false);
      } catch (error: any) {
        console.error(error);
        if (error && error.response && error.response.data) {
          setError({ show: true, message: "Profile doesn't exist" });
        }
        setSearchLoading(false);
      }
    },
  });
  const { search } = formik.values;
  /* ================================================== */
  /*  method */
  /* ================================================== */
  const handleClose = () => {
    onClose();
    formik.resetForm();
  };

  const handleTrackProfile = async (e: any) => {
    e.preventDefault();
    setTrackLoading(true);
    try {
      let apiUrl = socialMedia === "instagram" ? apiEndpoint.INSTAGRAM_PROFILE(search) : apiEndpoint.TIKTOK_PROFILE(search);
      const result = await http.post(apiUrl, {});

      if ("username" in result.data && result.data.username) {
        toast.success(`${result.data.username} is being tracked.`);
      }

      mutate();
      onClose();
      setTrackLoading(false);
      setShowConfirmDialog(false);
      formik.resetForm();
    } catch (error: any) {
      console.error(error);
      if (error && error.response && error.response.status === 400) {
        if (error.response.data && "detail" in error.response.data) {
          toast.error(error.response.data.detail);
        }
      }
      setTrackLoading(false);
    }
  };

  let SlotWarning = null;

  const slot_available = userSlotData && Object.keys(userSlotData).length > 0 && userSlotData.slot_available;

  const slot_change_remaining =
    (userSlotData && Object.keys(userSlotData).length > 0 && userSlotData.subscription && userSlotData.subscription.slot_change_remaining) ?? -1;

  if (userSlotData && Object.keys(userSlotData).length > 0 && slot_available !== undefined && !user?.isAdmin) {
    if (slot_available <= 0 || slot_change_remaining <= 0) {
      // if available = 0 and remaining is <= 0, meaning user can no longer delete anything and will have warning on using up slot changes

      SlotWarning = (
        <>
          <Typography variant="body1" color="error.light" fontWeight={500}>
            You have no available slot or changes left, considering upgrade plan&nbsp;
            <Link href="/pricing" underline="always">
              here
            </Link>
            {/* <Link
              rel="noopener noreferrer"
              href={`mailto:test@gmail.com?Subject=${encodeURIComponent("Upgrading to Pro")}&body=${encodeURIComponent(
                `Dear goIntec,\n\nI am writing to request an upgrade to the Pro subscription tier for my account with the username [${
                  user ? user.user : "-"
                }] and user id ${
                  user?.account_number
                }.\n\nCould you please provide me with information on how to proceed with upgrading my account? I am eager to access the enhanced functionalities available with the Lite subscription.\n\nThank you for your assistance.\n\nBest regards,\n${
                  user ? user.name : "-"
                }`
              )}`}
              sx={{ cursor: "pointer" }}
              underline="always"
              color={theme.palette.primary.main}
            >
              contacting us
            </Link> */}
            .
          </Typography>
        </>
      );
    }
  }

  /* ================================================== */
  /*  useEffect */
  /* ================================================== */
  useEffect(() => {
    // Check if the username has changed from the previous state
    if (search !== prevUsername) {
      setError({ show: false, message: "" });

      // Update the previous username
      setPrevUsername(search);
      // reset live search
      setLiveSearchProfile(null);
    }
  }, [search, setError, prevUsername, setPrevUsername]);

  useEffect(() => {
    if (open) {
      userSlotMutate();
    }
  }, [open, userSlotMutate]);

  /* ================================================== */
  /* ================================================== */
  return (
    <>
      <Dialog
        disableEscapeKeyDown={search !== ""}
        open={open}
        onClose={handleClose}
        maxWidth={"md"}
        fullWidth={true}
        sx={{
          "& .MuiPaper-root": smDown
            ? {
                margin: 0,
                width: "95%",
              }
            : null,
        }}
      >
        <DialogTitle>
          <Stack direction="row" alignItems="center" spacing={2}>
            {socialMedia === "instagram" && <img src={Insta} alt="insta" width="24px" height="24px" />}

            {socialMedia === "tiktok" && (
              <Box
                sx={{
                  width: "28px",
                  height: "28px",
                  background: "white",
                  border: "0.5px solid #808080",
                  borderRadius: "50%",
                  display: "grid",
                  placeItems: "center",
                }}
              >
                <img src={Tiktok} alt="insta" width="100%" height="100%" />
              </Box>
            )}

            <Typography variant="inherit" sx={{ flexGrow: 1 }}>
              Track&nbsp;{socialMedia === "instagram" ? "Instagram" : "Tik Tok"}&nbsp;Profile
            </Typography>
            <IconButton onClick={handleClose}>
              <CloseRoundedIcon fontSize="small" />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Stack spacing={1} sx={{ my: 2 }}>
            <Paper elevation={10} sx={{ px: 1, py: 4 }}>
              <Stack spacing={1} alignItems="center" justifyContent={"center"}>
                <Box sx={{ width: smDown ? "95%" : "80%", pb: 2 }}>
                  {/* <DelaySubmitInput
                    value={search}
                    variant="outlined"
                    setValue={setSearch}
                    clearInput={search === ""}
                    placeholder={`Track ${socialMedia} username`}
                  /> */}

                  <TrackInput
                    user={user}
                    inputRef={inputRef}
                    error={error}
                    formik={formik}
                    socialMedia={socialMedia}
                    disableInput={(slot_available !== undefined && slot_available <= 0) || slot_change_remaining <= 0}
                    searchLoading={searchLoading}
                    placeholder={`Enter exact ${socialMedia} @username here`}
                  />
                </Box>

                {liveSearchProfile ? (
                  <Box sx={{ display: "grid", placeItems: "center", my: 2 }}>
                    {userSlotData &&
                      Object.keys(userSlotData).length > 0 &&
                      ((slot_available !== undefined && slot_available <= 0) || slot_change_remaining <= 0) && (
                        <Box sx={{ textAlign: "center", mb: 1 }}>
                          <Typography variant="body1" color="error.light" fontWeight={500}>
                            {SlotWarning}
                          </Typography>
                        </Box>
                      )}
                    <Stack sx={{ width: "200px" }} spacing={2}>
                      <Paper elevation={5} sx={{ border: `1px solid ${theme.palette.divider}` }}>
                        <Stack sx={{ my: 3 }}>
                          <Stack alignItems="center" spacing={1}>
                            <Box sx={{ position: "relative", width: smDown ? "80px" : "120px", height: smDown ? "80px" : "120px" }}>
                              <Box
                                sx={{
                                  borderRadius: "50%",
                                  overflow: "hidden",
                                  display: "grid",
                                  placeItems: "center",
                                  border: "1px solid #dcdcdc",
                                  width: smDown ? "80px" : "120px",
                                  height: smDown ? "80px" : "120px",
                                }}
                              >
                                <img
                                  src={`${liveSearchProfile.downloaded_image}`}
                                  alt={liveSearchProfile.username}
                                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                                />
                              </Box>
                              <Box
                                sx={{
                                  position: "absolute",
                                  bottom: 0,
                                  right: 0,
                                  width: smDown ? "15px" : "25px",
                                  height: smDown ? "15px" : "25px",
                                  background: "white",
                                  border: "1px solid #dcdcdc",
                                  borderRadius: "50%",
                                  padding: 0.5,
                                  display: "grid",
                                  placeItems: "center",
                                }}
                              >
                                <img src={socialMedia === "tiktok" ? Tiktok : Insta} alt="tiktok" width="100%" height="100%" />
                              </Box>
                            </Box>
                            <Box>
                              <Link
                                onClick={e => {
                                  e.stopPropagation();
                                  window.open(`https://www.instagram.com/${search}`, "_blank");
                                }}
                                href={`${socialMedia === "instagram" ? "https://www.instagram.com/" : "https://www.tiktok.com/"}${search}`}
                                rel="noopener noreferrer"
                                target="_blank"
                                variant="caption"
                                color="primary.main"
                                underline="always"
                                sx={{ whiteSpace: "nowwrap", cursor: "pointer" }}
                              >
                                <Typography variant="body1" color="primary.main">
                                  @{search}
                                </Typography>
                              </Link>
                            </Box>
                          </Stack>
                        </Stack>
                      </Paper>
                      <Button
                        disabled={
                          liveSearchProfile.is_private ||
                          liveSearchProfile.is_tracked ||
                          (!user?.isAdmin && userSlotData && Object.keys(userSlotData).length > 0 && userSlotData.slot_available === 0)
                        }
                        form="profileForm"
                        type="submit"
                        sx={{ mt: 1, wordBreak: "break-all" }}
                        size="large"
                        variant="contained"
                        onClick={e => {
                          e.preventDefault();
                          setShowConfirmDialog(true);
                        }}
                      >
                        <Typography variant="body1" fontWeight={600}>
                          Click to track
                        </Typography>
                      </Button>
                    </Stack>
                    <Typography mt={1} variant="body1" color="error.light" fontWeight={500}>
                      {liveSearchProfile.is_private
                        ? "This account is private"
                        : liveSearchProfile.is_tracked
                        ? "You are already tracking this account"
                        : ""}
                    </Typography>
                  </Box>
                ) : (
                  <Stack alignItems="center">
                    <Box sx={{ textAlign: "center", mb: 1 }}>
                      {error.show && (
                        <Typography variant="body1" color="error.light" fontWeight={500}>
                          {error.message}
                        </Typography>
                      )}

                      <>{SlotWarning}</>
                    </Box>

                    <img src={Search} alt="insta" width={smDown ? "90%" : "400px"} />
                  </Stack>
                )}
              </Stack>
            </Paper>
          </Stack>
        </DialogContent>
      </Dialog>

      <AlertDialog
        preventBackdropClick
        loading={trackLoading}
        open={showConfirmDialog}
        onClose={() => setShowConfirmDialog(false)}
        handleConfirm={handleTrackProfile}
        title={"Tracking Confirmation"}
        confirmText={"Yes, I'm sure"}
      >
        <Stack spacing={0.5} py={2}>
          <Box sx={{ display: "grid", placeItems: "center", py: 2 }}>
            {liveSearchProfile && (
              <Box sx={{ position: "relative", width: smDown ? "80px" : "120px", height: smDown ? "80px" : "120px" }}>
                <Box
                  sx={{
                    borderRadius: "50%",
                    overflow: "hidden",
                    display: "grid",
                    placeItems: "center",
                    border: "1px solid #dcdcdc",
                    width: smDown ? "80px" : "120px",
                    height: smDown ? "80px" : "120px",
                  }}
                >
                  <img
                    src={`${liveSearchProfile.downloaded_image}`}
                    alt={liveSearchProfile.username}
                    style={{ width: "100%", height: "100%", objectFit: "cover" }}
                  />
                </Box>
                <Box
                  sx={{
                    position: "absolute",
                    bottom: 0,
                    right: 0,
                    width: smDown ? "15px" : "25px",
                    height: smDown ? "15px" : "25px",
                    background: "white",
                    border: "1px solid #dcdcdc",
                    borderRadius: "50%",
                    padding: 0.5,
                    display: "grid",
                    placeItems: "center",
                  }}
                >
                  <img src={socialMedia === "tiktok" ? Tiktok : Insta} alt="tiktok" width="100%" height="100%" />
                </Box>
              </Box>
            )}
          </Box>

          <Stack>
            <Typography variant="body1" fontWeight={500}>
              Are you sure you want to track <span style={{ color: theme.palette.primary.main }}>@{search}</span>?
            </Typography>

            {!user?.isAdmin && (
              <>
                <Stack direction="row" spacing={0.5} alignItems="center" sx={{ mt: 1 }}>
                  <FontAwesomeIcon icon={faCircleInfo} color={theme.palette.text.secondary} />
                  <Typography variant="caption" fontStyle={"italic"} color="text.secondary">
                    Profile Swap is the number of times you can modify a subscribed
                    <br /> slot for tracking a social media account.
                  </Typography>
                </Stack>
                <Stack>
                  <Box
                    sx={{
                      background: (theme.palette.neutral as any)[theme.palette.mode === "dark" ? 800 : 200],
                      border: `1px solid ${(theme.palette.neutral as any)[300]}`,
                      display: "grid",
                      placeItems: "center",
                      p: 1,
                      mt: 1,
                      borderRadius: 1,
                    }}
                  >
                    <Typography variant={"body2"}>Profile Swaps</Typography>
                    {userSlotData && userSlotData.subscription && checkObjectNotEmpty(userSlotData.subscription) && (
                      <Stack direction={"row"} alignItems="center" spacing={1}>
                        <Typography fontWeight="normal" variant={"h6"}>
                          &nbsp;{userSlotData.subscription.slot_change_remaining}&nbsp;
                        </Typography>
                        <FontAwesomeIcon icon={faArrowRight} />
                        <Typography variant={"h6"} fontWeight="bolder" color="primary.main">
                          {userSlotData.subscription.slot_change_remaining ? userSlotData.subscription.slot_change_remaining - 1 : "-"}
                        </Typography>
                      </Stack>
                    )}
                  </Box>
                </Stack>
              </>
            )}
          </Stack>
        </Stack>
      </AlertDialog>
    </>
  );
};

export default SearchAccountDialog;
