import { Box, Button, Typography } from "@mui/material";
import axios from "axios";
import React, { useCallback, useEffect } from "react";
import useAuth from "src/hooks/use-auth";
import useHttp from "src/hooks/use-http";
/* components */
/* 3rd party lib */
/* Util */
import FacebookLogo from "src/images/fb.png";

interface FacebookLoginButtonProps {}

type Props = FacebookLoginButtonProps;

const FacebookLoginButton: React.FC<Props> = () => {
  const { apiEndpoint } = useHttp();

  const { socialMediaLogin } = useAuth();

  // Fetch user details after successful login
  const callFBAuthApi = useCallback(
    async (response: any) => {
      console.log(response.authResponse.accessToken);
      try {
        await axios
          .post(`${process.env.REACT_APP_BASE_URL}${apiEndpoint.FACEBOOK_AUTH.substring(1)}`, {
            access_token: response.authResponse.accessToken,
          })
          .then(res => {
            if (res.data) {
              console.log("Access", res.data.access);
              console.log("Refresh", res.data.refresh);

              socialMediaLogin(res.data.access, res.data.refresh);
            }
          })
          .catch(err => console.error(err));
      } catch (err) {
        console.error(err);
      }
    },
    [apiEndpoint, socialMediaLogin]
  );

  // Callback function to handle login status
  const statusChangeCallback = useCallback(
    (response: any) => {
      if (response.status === "connected") {
        callFBAuthApi(response);
      }
    },
    [callFBAuthApi]
  );

  useEffect(() => {
    const loadFacebookSDK = () => {
      // Load the SDK asynchronously
      (function (d, s, id) {
        var js,
          fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) return;
        js = d.createElement(s) as HTMLScriptElement;
        js.id = id;
        js.src = "https://connect.facebook.net/en_US/all.js";
        js.crossOrigin = "anonymous";
        fjs.parentNode?.insertBefore(js, fjs);
      })(document, "script", "facebook-jssdk");
    };

    loadFacebookSDK();

    (window as any).FB.init({
      appId: "541659868438397",
      cookie: true,
      xfbml: true,
      version: "v22.0",
    });
  }, []);

  // Handle Facebook login button click
  const handleFacebookLogin = () => {
    (window as any).FB.login(
      (response: any) => {
        statusChangeCallback(response);
      },
      { scope: "public_profile,email" }
    );
  };

  return (
    <Button
      variant="outlined"
      onClick={handleFacebookLogin as any}
      sx={{
        position: "relative",
        background: "#4267B2",
        "&:hover": {
          background: "#274787",
        },
      }}
    >
      <Box sx={{ position: "absolute", height: "32px", width: "32px", left: "8px", top: "50%", transform: "translateY(-50%)" }}>
        <img height="32px" width="32px" src={FacebookLogo} alt="google" />
      </Box>

      <Typography variant="body1" color="white">
        Continue with Facebook
      </Typography>
    </Button>
  );
};

export default FacebookLoginButton;
