import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Stack, Typography } from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { useTranslation } from "react-i18next";
import { BUTTON_CANCEL, BUTTON_CONFIRM } from "src/constants/translate-keys/common";
import { getTranslateString } from "src/utils/translate";

interface AlertDialogProps {
  open: boolean;
  title?: any;
  danger?: boolean;
  loading?: boolean;
  showCancelButton?: boolean;
  closeText?: string;
  confirmText?: string;
  dialogContentStyle?: any;
  preventBackdropClick?: boolean;
  onClose: () => void;
  handleConfirm: (e: any) => void;
  children: React.ReactNode;
}

type Props = AlertDialogProps;

const AlertDialog: React.FC<Props> = props => {
  const { t } = useTranslation();
  const {
    open,
    onClose,
    handleConfirm,
    title = "Alert",
    showCancelButton = true,
    dialogContentStyle = {},
    closeText = getTranslateString(t, BUTTON_CANCEL),
    confirmText = getTranslateString(t, BUTTON_CONFIRM),
    preventBackdropClick = false,
    loading = false,
    danger = false,
    children,
  } = props;

  return (
    <Dialog
      maxWidth="sm"
      // {...props}
      open={open}
      onClose={(_, reason) => {
        if (preventBackdropClick && reason && reason === "backdropClick") return;
        onClose();
      }}
    >
      <DialogTitle>
        <Stack direction="row" alignItems="center" justifyContent={"space-between"} spacing={3}>
          {title && (
            <>
              {typeof title === "string" ? (
                <Typography variant="inherit" sx={{ flexGrow: 1 }}>
                  {title}
                </Typography>
              ) : (
                title
              )}
            </>
          )}
          <IconButton onClick={onClose}>
            <CloseRoundedIcon fontSize="small" />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent sx={dialogContentStyle}>{children}</DialogContent>
      <DialogActions>
        {showCancelButton && (
          <Button variant="outlined" onClick={onClose}>
            {closeText}
          </Button>
        )}
        <Button disabled={loading} variant="contained" color={danger ? "error" : "primary"} onClick={handleConfirm} autoFocus>
          {confirmText}
          {loading && <CircularProgress sx={{ ml: 1 }} size={18} color="inherit" />}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AlertDialog;
