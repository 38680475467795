import React /* { useCallback }  */ from "react";
/* components */
// import ToggleComponent from "src/components/comparison/toggle-component";
import DateFilter from "src/components/common/inputs/date-filter";

/* 3rd party lib */
// import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDownBigSmall, faCircleExclamation /* faFloppyDisk, */ } from "@fortawesome/pro-solid-svg-icons";
import { Button, Stack, ToggleButton, Tooltip, Typography } from "@mui/material";
/* Util */
import useMediaQueries from "src/hooks/use-mediaqueries";
import { useComparisonPostContext } from "src/contexts/comparison-post-context";
import AlertDialog from "src/components/common/dialog/alert-dialog";
import ToggleComponent from "../toggle-component";
import { faExpand } from "@fortawesome/pro-regular-svg-icons";
// import { maxPreset, minCompare } from "src/pages/comparisons/profiles/profiles";
// import { toast } from "react-hot-toast";

interface ComparisonInputsProps {
  showRemoveAllDialog: boolean;
  setShowRemoveAllDialog: React.Dispatch<React.SetStateAction<boolean>>;
}

type Props = ComparisonInputsProps;

const ComparisonInputs: React.FC<Props> = ({ showRemoveAllDialog, setShowRemoveAllDialog }) => {
  /* ================================================== */
  /*  state */
  /* ================================================== */
  const { lgUp } = useMediaQueries();
  const {
    // presetData,
    // savePresetDisabled,
    // setCrudPresetDialog,
    // setTriggerVibration,
    theme,
    formik,
    postIds,
    postExists,
    displayMode,
    currentActive,
    tableDirection,
    openFullScreenDialog,
    setPostIds,
    setDisplayMode,
    setPresetDialog,
    setOpenSortPost,
    handleEditPreset,
    setTableDirection,
    setOpenFullScreenDialog,
  } = useComparisonPostContext();

  /* ================================================== */
  /*  method */
  /* ================================================== */
  // const checkArrayExist = useCallback(
  //   (currArray: string[]) => {
  //     if (presetData) {
  //       for (let i = 0; i < presetData.length; i++) {
  //         const objArray = presetData[i];
  //         let targetArray = objArray?.posts.map(child => child.post_id);

  //         // Sort both arrays before comparing to ignore sequence
  //         if (_.isEqual(currArray.slice().sort(), targetArray.slice().sort())) {
  //           // if array same that means it exist
  //           return true;
  //         }
  //       }
  //     }
  //     return false;
  //   },
  //   [presetData]
  // );
  /* ================================================== */
  /*  useEffect */
  /* ================================================== */

  /* ================================================== */
  /* ================================================== */
  return (
    <>
      <Stack direction="row" alignItems="center" justifyContent={"space-between"}>
        <Stack direction="row" alignItems="center" spacing={1}>
          {postExists && lgUp && (
            <Stack direction="row" alignItems="center" spacing={1}>
              {/* <ToggleComponent displayMode={displayMode} setDisplayMode={setDisplayMode} /> */}
              <Tooltip arrow title="Reorder Posts">
                <ToggleButton
                  className="active-step1"
                  value="check"
                  onClick={() => setOpenSortPost(true)}
                  sx={{
                    "&:hover": {
                      background: theme.palette.primary.main,
                      color: "white",
                    },
                    "&:active": {
                      background: "white",
                      color: theme.palette.primary.main,
                    },
                    color: theme.palette.primary.main,
                  }}
                >
                  <FontAwesomeIcon icon={faArrowDownBigSmall} style={{ transform: "rotate(-90deg)" }} />
                </ToggleButton>
              </Tooltip>
              <ToggleComponent
                displayMode={displayMode}
                tableDirection={tableDirection}
                setDisplayMode={setDisplayMode}
                setTableDirection={setTableDirection}
                profileOrPosts={postIds}
              />
              <DateFilter maxWidth={90} formik={formik} />
            </Stack>
          )}
          {(!lgUp || openFullScreenDialog) && (
            <Button variant="outlined" color="primary" onClick={() => setPresetDialog(true)} size="small" className="step-2">
              <Typography variant="body1" color="primary.main">
                Preset
              </Typography>
            </Button>
          )}
          {/* {presetData && presetData.length < 10 && (
          <Button
            startIcon={!smDown && <FontAwesomeIcon color={"white"} icon={faFloppyDisk} />}
            variant="contained"
            color="primary"
            onClick={() => {
              let msg = "";
              if (postIds.filter(child => child !== "").length < minCompare || postIds.every(child => child === "")) {
                msg = "Please select at least 2 posts to save as a preset.";
                setTriggerVibration("select");
              } else if (presetData && presetData.length >= maxPreset) {
                msg = "Unable to add preset. Preset limit reached. Please remove existing presets.";
                setTriggerVibration("preset");
              } else if (checkArrayExist(postIds)) {
                msg = "Preset already exist, please switch, remove or add post.";
                setTriggerVibration("preset");
              } else {
                setCrudPresetDialog({ create: false, update: true });
              }

              if (msg !== "") {
                toast.error(msg);
                // Reset the animation after a short delay
                setTimeout(() => {
                  setTriggerVibration(null);
                }, 300);
              }
            }}
            disabled={savePresetDisabled}
            // size="small"
            sx={{ height: "36.75px" }}
          >
            {smDown ? <FontAwesomeIcon color={"white"} icon={faFloppyDisk} size="lg" /> : <Typography variant="body1">Save Preset</Typography>}
          </Button>
        )} */}
          {!openFullScreenDialog && (
            <Tooltip arrow title="Full Screen">
              <ToggleButton
                value="check"
                className="step-4"
                onClick={() => setOpenFullScreenDialog(true)}
                sx={{
                  // position: "absolute",
                  // right: "8px",
                  // top: "50%",
                  // transform: "translateY(-50%)",
                  "&:hover": {
                    background: theme.palette.primary.main,
                    color: "white",
                  },
                  "&:active": {
                    background: "white",
                    color: theme.palette.primary.main,
                  },
                  color: theme.palette.primary.main,
                }}
              >
                <FontAwesomeIcon icon={faExpand} style={{ transform: "rotate(-90deg)" }} />
              </ToggleButton>
            </Tooltip>
          )}
        </Stack>
      </Stack>

      <AlertDialog
        open={showRemoveAllDialog}
        onClose={() => setShowRemoveAllDialog(false)}
        handleConfirm={() => {
          if (currentActive && currentActive.preset) {
            setPostIds(["", ""]);
            handleEditPreset(currentActive.preset.id, currentActive.preset.title, ["", ""]);
          } else {
            setPostIds(["", ""]);
          }
          setShowRemoveAllDialog(false);
        }}
        title={
          <Stack direction="row" alignItems={"center"} spacing={1}>
            <FontAwesomeIcon style={{ color: theme.palette.error.main }} icon={faCircleExclamation} size="lg" />
            <Typography variant="body1" color="error.main" fontWeight={"bolder"}>
              WARNING
            </Typography>
          </Stack>
        }
        confirmText={"Clear All"}
        danger
      >
        {currentActive && currentActive.preset ? (
          <Typography variant="body1" py={2}>
            Are you sure you want to clear all posts in
            <span style={{ color: theme.palette.primary.main, fontWeight: "bolder" }}>
              &nbsp;
              {currentActive.preset ? currentActive.preset.title : "the preset"}
            </span>
            ? This action is irreversible.
          </Typography>
        ) : (
          <Typography variant="body1" py={2}>
            Are you sure you want to clear all the posts? This action is irreversible.
          </Typography>
        )}
      </AlertDialog>
    </>
  );
};

export default ComparisonInputs;
