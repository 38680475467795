import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { styled, Drawer, IconButton, Box, Stack, Divider, Typography } from "@mui/material";
import { ReactNode } from "react";
import useMediaQueries from "src/hooks/use-mediaqueries";

interface MobileDrawerProps {
  header: string | ReactNode;
  children: ReactNode;
  open: boolean;
  onClose: () => void;
  titleColor?: string;
}

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  zIndex: theme.zIndex.appBar + 200,
}));

const MobileDrawer: React.FC<MobileDrawerProps> = ({ header, children, open, onClose, titleColor = "primary.main" }) => {
  const { smDown } = useMediaQueries();
  return (
    <StyledDrawer
      anchor={smDown ? "bottom" : "right"}
      open={open}
      onClose={onClose}
      sx={{
        height: smDown ? "90vh" : "100vh",
        "& .MuiDrawer-paper": {
          marginBottom: smDown ? "16px" : 0,
          marginLeft: smDown ? "8px" : 0,
          marginRight: smDown ? "8px" : 0,
          borderRadius: smDown ? "16px" : 0,
          // maxHeight: "90vh",
        },
      }}
    >
      {/* Add an empty theme object for TypeScript */}
      <Box sx={{ p: 2, width: smDown ? "auto" : "350px", height: "100%" }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          {typeof header === "string" ? (
            <Typography variant="h6" color={titleColor}>
              {header}
            </Typography>
          ) : (
            header
          )}
          <IconButton onClick={onClose}>
            <FontAwesomeIcon icon={faTimes} size="xs" />
          </IconButton>
        </Stack>
        <Divider sx={{ mb: 2, mt: 1 }} />
        {children}
      </Box>
    </StyledDrawer>
  );
};

export default MobileDrawer;
