import React, { useEffect } from "react";
/* components */
import DataDisplay from "src/components/common/inputs/data-display";
/* 3rd party lib */
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Stack,
  styled,
  TextField,
  Typography,
  InputAdornment,
  FormControl,
  Select,
  MenuItem,
  OutlinedInput,
  FormHelperText,
  useTheme,
  Paper,
} from "@mui/material";
import useSWR from "swr";
import moment from "moment";
import { FormikProps } from "formik";
import { MobileDatePicker } from "@mui/x-date-pickers";
import { faCalendar } from "@fortawesome/pro-regular-svg-icons";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

/* Util */
import useHttp from "src/hooks/use-http";
import useMediaQueries from "src/hooks/use-mediaqueries";
import { ISubscriptionPackageData } from "src/types/common";
import AlertDialog from "src/components/common/dialog/alert-dialog";
import { IEditDialog, IFormikSubscriptionPlan } from "src/pages/admin/subscription-plan-table";
import { getTranslateString } from "src/utils/translate";
import { BUTTON_DELETE, PROMPT_DELETE_CONFIRMATION } from "src/constants/translate-keys/common";
import { useTranslation } from "react-i18next";

interface EditSubscriptionDialogProps {
  loading: boolean;
  onClose: () => void;
  showDeleteDialog: boolean;
  editDialogOpen: IEditDialog;
  onDelete: (id: number) => void;
  formik: FormikProps<IFormikSubscriptionPlan>;
  setShowDeleteDialog: React.Dispatch<React.SetStateAction<boolean>>;
}

interface RootProps {
  maxWidth?: number | string;
}

const StyledDateTextField = styled(TextField, { shouldForwardProp: props => props !== "disablePadding" })<RootProps>(({ maxWidth }) => ({
  width: "100%",
  "& .MuiInputBase-input": {
    paddingBottom: "9px !important",
    paddingTop: "8px !important",
    maxWidth: maxWidth,
    width: "100%",
  },
}));

type Props = EditSubscriptionDialogProps;

const EditSubscriptionDialog: React.FC<Props> = ({ showDeleteDialog, setShowDeleteDialog, editDialogOpen, onDelete, formik, loading, onClose }) => {
  /* ================================================== */
  /*  state */
  /* ================================================== */
  const theme = useTheme();
  const { t } = useTranslation();
  const { smDown, mdDown } = useMediaQueries();
  const { apiEndpoint } = useHttp();
  const { data } = useSWR<ISubscriptionPackageData>([apiEndpoint.SUBSCRIPTION_PLAN_PACKAGE]);
  /* ================================================== */
  /*  method */
  /* ================================================== */

  const handleFocus = (event: any) => {
    event.target.select();
  };

  const getDurationDifference = (start: string, end: string) => {
    return Math.abs(moment(start).diff(moment(end), "days"));
  };

  /* ================================================== */
  /*  useEffect */
  /* ================================================== */
  useEffect(() => {
    if (editDialogOpen.data && editDialogOpen.open) {
      formik.setFieldValue("start_time", editDialogOpen.data.start_time);
      formik.setFieldValue("end_time", editDialogOpen.data.end_time);
      formik.setFieldValue("duration", editDialogOpen.data.duration_days);
      formik.setFieldValue("account", editDialogOpen.data.account);
      formik.setFieldValue("package", editDialogOpen.data.package ? editDialogOpen.data.package.id : null);
      formik.setFieldValue("remark", editDialogOpen.data.remark);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editDialogOpen]);
  /* ================================================== */
  /* ================================================== */
  return (
    <>
      <Dialog maxWidth="md" fullWidth open={editDialogOpen.open} onClose={onClose}>
        <form id="edit_subscription_form" onSubmit={formik.handleSubmit}>
          <DialogTitle sx={{ p: 2 }}>
            <Stack alignItems="center" direction="row" spacing={3}>
              <Typography variant="inherit" sx={{ flexGrow: 1 }}>
                Edit Subscription Plan
              </Typography>
              <IconButton onClick={onClose}>
                <CloseRoundedIcon fontSize="small" />
              </IconButton>
            </Stack>
          </DialogTitle>
          <DialogContent>
            <Paper sx={{ p: 2, mt: 2 }} elevation={5}>
              <Grid container sx={{ pt: 2 }} spacing={2}>
                <Grid item xs={12}>
                  <DataDisplay
                    alignCenter={false}
                    label={"Start Date"}
                    variant={"body2"}
                    value={
                      <MobileDatePicker
                        closeOnSelect
                        mask="__-__-____"
                        // name="start_max"
                        value={formik.values.start_time}
                        inputFormat={"DD-MM-YYYY"}
                        onChange={date => {
                          const start_time = date;
                          formik.setFieldValue("start_time", start_time);
                          if (formik.values.end_time && start_time) {
                            // if end time exist, then get the duration difference
                            formik.setFieldValue("duration", getDurationDifference(start_time, formik.values.end_time));
                            if (moment(formik.values.end_time).isBefore(moment(start_time))) {
                              //  if end time is less than start time then change end time to start time
                              formik.setFieldValue("end_time", start_time);
                              formik.setFieldValue("duration", getDurationDifference(start_time, start_time));
                            }
                          }
                        }}
                        renderInput={params => (
                          <StyledDateTextField
                            {...params}
                            variant="outlined"
                            name="start_time"
                            hiddenLabel
                            maxWidth={"100%"}
                            error={formik.errors.start_time ? true : false}
                            helperText={formik.errors.start_time}
                            placeholder={mdDown ? "Start Date" : "Click to enter start date"}
                            value={moment(formik.values.start_time).format("YYYY-MM-DD")}
                          />
                        )}
                        InputProps={{
                          required: true,
                          // Add the icon as the end adornment
                          endAdornment: (
                            <InputAdornment position="end">
                              <FontAwesomeIcon icon={faCalendar} />
                            </InputAdornment>
                          ),
                        }}
                      />
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <DataDisplay
                    alignCenter={false}
                    label={"End Date"}
                    variant={"body2"}
                    value={
                      <MobileDatePicker
                        closeOnSelect
                        mask="__-__-____"
                        // name="start_max"
                        minDate={moment(formik.values.start_time).format("YYYY-MM-DD")}
                        value={formik.values.end_time}
                        inputFormat={"DD-MM-YYYY"}
                        onChange={date => {
                          const end_time = date;
                          formik.setFieldValue("end_time", end_time);
                          if (formik.values.start_time && end_time) {
                            // if end time exist, then get the duration difference
                            formik.setFieldValue("duration", getDurationDifference(end_time, formik.values.start_time));
                          }
                        }}
                        renderInput={params => (
                          <StyledDateTextField
                            {...params}
                            variant="outlined"
                            name="end_time"
                            hiddenLabel
                            maxWidth={"100%"}
                            error={formik.errors.end_time ? true : false}
                            helperText={formik.errors.end_time}
                            placeholder={mdDown ? "End Date" : "Click to enter end date"}
                            value={moment(formik.values.end_time).format("YYYY-MM-DD")}
                          />
                        )}
                        InputProps={{
                          required: true,
                          // Add the icon as the end adornment
                          endAdornment: (
                            <InputAdornment position="end">
                              <FontAwesomeIcon icon={faCalendar} />
                            </InputAdornment>
                          ),
                        }}
                      />
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <DataDisplay
                    label={"Duration (Days)"}
                    variant={"body2"}
                    alignCenter={false}
                    value={
                      <Box sx={{ width: "100%" }}>
                        <FormControl fullWidth>
                          <TextField
                            type="number"
                            value={formik.values.duration}
                            fullWidth
                            variant="outlined"
                            hiddenLabel
                            name="duration"
                            size="small"
                            placeholder="Enter Remark"
                            onChange={e => {
                              formik.handleChange(e);
                              // when duration is changed, make sure both start and end time exist and add time to start
                              if (formik.values.start_time) {
                                formik.setFieldValue("end_time", moment(formik.values.start_time).add(e.target.value, "days"));
                              }
                            }}
                            InputProps={{ inputProps: { min: 0 }, onFocus: handleFocus, onClick: handleFocus }}
                          />
                        </FormControl>
                      </Box>
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <DataDisplay
                    label={"Package"}
                    variant={"body2"}
                    value={
                      <Box sx={{ width: "100%" }}>
                        <FormControl fullWidth>
                          <Select
                            value={formik.values.package}
                            onChange={e => {
                              formik.setFieldValue("package", e.target.value);
                            }}
                            size="small"
                            displayEmpty
                            placeholder="Select Package"
                            inputProps={{ "aria-label": "Without label" }}
                            renderValue={selected => {
                              if ((selected && selected.toString().toLowerCase() === "null") || selected === null) {
                                return (
                                  <Typography fontStyle={"normal"} variant="body2" fontWeight={"light"} color={"text.disabled"}>
                                    Select a Package
                                  </Typography>
                                );
                              } else {
                                return data?.results.filter(child => child.id === parseInt(selected))[0].title;
                              }
                            }}
                            input={
                              <OutlinedInput
                                size="small"
                                sx={{
                                  ".MuiNativeSelect-select": {
                                    background: "none",
                                  },
                                  ".MuiSelect-select": {
                                    background: "none",
                                  },

                                  ".MuiOutlinedInput-notchedOutline": {
                                    borderColor: formik.errors.package && formik.values.package === "null" ? "#D14343" : "",
                                  },
                                }}
                              />
                            }
                          >
                            <MenuItem value={"null"} sx={{ textTransform: "uppercase", color: theme.palette.text.disabled }}>
                              Select a package
                            </MenuItem>

                            {data?.results.map(child => (
                              <MenuItem value={child.id} key={child.id}>
                                <Stack direction="row" alignItems="center" spacing={1}>
                                  <Typography variant="body1">{child.title}</Typography>
                                </Stack>
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {formik.errors.package && formik.values.package === "null" && <FormHelperText error>{formik.errors.package}</FormHelperText>}
                      </Box>
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <DataDisplay
                    label={"Remark"}
                    alignCenter={false}
                    variant={smDown ? "caption" : "body2"}
                    value={
                      <TextField
                        value={formik.values.remark}
                        fullWidth
                        variant="outlined"
                        hiddenLabel
                        name="remark"
                        size="small"
                        multiline={true}
                        minRows={2}
                        placeholder="Enter Remark"
                        onChange={formik.handleChange}
                      />
                    }
                  />
                </Grid>
              </Grid>
            </Paper>
          </DialogContent>
        </form>

        <DialogActions sx={{ p: 2 }}>
          <Stack direction="row" spacing={1} justifyContent="space-between" sx={{ width: "100%" }}>
            <Button
              variant="contained"
              color="error"
              onClick={() => {
                setShowDeleteDialog(true);
              }}
              size="small"
            >
              {getTranslateString(t, BUTTON_DELETE)}
            </Button>
            <Stack direction="row" spacing={1}>
              <Button variant="outlined" onClick={onClose}>
                Cancel
              </Button>
              <Button form="edit_subscription_form" type="submit" disabled={loading} variant="contained" color={"primary"}>
                Save
                {loading && <CircularProgress sx={{ ml: 1 }} size={18} color="inherit" />}
              </Button>
            </Stack>
          </Stack>
        </DialogActions>
      </Dialog>

      <AlertDialog
        open={showDeleteDialog}
        onClose={() => setShowDeleteDialog(false)}
        handleConfirm={() => editDialogOpen.data && onDelete(editDialogOpen.data.id)}
        title={getTranslateString(t, PROMPT_DELETE_CONFIRMATION)}
        confirmText={getTranslateString(t, BUTTON_DELETE)}
        danger
      >
        <Paper sx={{ mt: 2, p: 2 }}>
          <Typography variant="body1" py={2}>
            Are you sure you want to delete this subscription plan? This action is irreversible.
          </Typography>
        </Paper>
      </AlertDialog>
    </>
  );
};

export default EditSubscriptionDialog;
