/* components */
import DataDisplay from "src/components/common/inputs/data-display";
import AlertDialog from "src/components/common/dialog//alert-dialog";
/* 3rd party lib */
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import React, { useCallback, useEffect, useRef } from "react";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
/* Util */
import { IPostPresetData, IProfilePresetData } from "src/types/instagram";
import useMediaQueries from "src/hooks/use-mediaqueries";
import { getTranslateString } from "src/utils/translate";
import { BUTTON_CANCEL, BUTTON_DELETE, PROMPT_DELETE_CONFIRMATION } from "src/constants/translate-keys/common";

interface IFormValues {
  title: string;
}

interface EditPresetDialogProps {
  open: boolean;
  onClose: () => void;
  preset: IPostPresetData | IProfilePresetData | null;
  onDelete: (id: number) => void;
  onEdit: (id: number, title: string) => void;
  showDeleteDialog: boolean;
  setShowDeleteDialog: React.Dispatch<React.SetStateAction<boolean>>;
}

type Props = EditPresetDialogProps;

const EditPresetDialog: React.FC<Props> = ({ open, onClose, preset, onEdit, onDelete, showDeleteDialog, setShowDeleteDialog }) => {
  /* ================================================== */
  /*  state */
  /* ================================================== */
  const theme = useTheme();
  const { t } = useTranslation();
  const nameFieldRef = useRef<HTMLDivElement | null>(null);
  const { smDown } = useMediaQueries();

  const formik = useFormik<IFormValues>({
    validateOnChange: false,
    initialValues: {
      title: "",
    },
    onSubmit: values => {
      if (preset) {
        onEdit(preset.id, values.title);
      }
      onClose();
    },
  });

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (open && nameFieldRef.current) {
        nameFieldRef.current.focus();
      }
    }, 0);

    return () => clearTimeout(timeout);
  }, [open]);

  /* ================================================== */
  /*  method */
  /* ================================================== */
  const handleConfirm = useCallback(() => {
    preset && onDelete(preset.id);
  }, [preset, onDelete]);
  /* ================================================== */
  /*  useEffect */
  /* ================================================== */
  useEffect(() => {
    if (preset && open) {
      formik.setFieldValue("title", preset.title);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preset, open]);

  useEffect(() => {
    const handleKeyDown = (event: any) => {
      if (open && showDeleteDialog) {
        if (event.key === "Enter") {
          handleConfirm();
        }
      }
    };
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [open, showDeleteDialog, handleConfirm]);

  /* ================================================== */
  /* ================================================== */
  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
        <DialogTitle>
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Typography variant="inherit">Rename Preset</Typography>
            <IconButton onClick={onClose}>
              <CloseRoundedIcon fontSize="small" />
            </IconButton>
          </Stack>
        </DialogTitle>
        <form id="presetForm" onSubmit={formik.handleSubmit}>
          <DialogContent
            sx={{
              ...(theme as any).custom.scroll,
            }}
          >
            <Paper sx={{ p: 1 }}>
              <Grid container>
                <Grid item xs={12}>
                  <DataDisplay
                    label={"Title"}
                    variant={smDown ? "caption" : "body2"}
                    value={
                      <TextField
                        // inputRef={nameFieldRef}
                        inputProps={{
                          ref: nameFieldRef,
                        }}
                        // ref={nameFieldRef}
                        // autoFocus={true}
                        value={formik.values.title}
                        fullWidth
                        variant="outlined"
                        hiddenLabel
                        name="title"
                        size="small"
                        required
                        onChange={formik.handleChange}
                      />
                    }
                  />
                </Grid>
              </Grid>
            </Paper>
          </DialogContent>
          <DialogActions>
            <Stack direction="row" spacing={1} justifyContent="space-between" sx={{ width: "100%" }}>
              <Button
                variant="contained"
                color="error"
                onClick={() => {
                  setShowDeleteDialog(true);
                }}
                size="small"
              >
                {getTranslateString(t, BUTTON_DELETE)}
              </Button>
              <Stack direction="row" spacing={1}>
                <Button variant="outlined" onClick={onClose} size="small">
                  {getTranslateString(t, BUTTON_CANCEL)}
                </Button>
                <Button form="presetForm" type="submit" variant="contained" color="primary" size="small">
                  Confirm
                </Button>
              </Stack>
            </Stack>
          </DialogActions>
        </form>
      </Dialog>

      <AlertDialog
        open={showDeleteDialog}
        onClose={() => {
          setShowDeleteDialog(false);
        }}
        handleConfirm={handleConfirm}
        title={getTranslateString(t, PROMPT_DELETE_CONFIRMATION)}
        confirmText={getTranslateString(t, BUTTON_DELETE)}
        danger
      >
        <Typography variant="body1" py={2}>
          Are you sure you want to delete this preset? This action is irreversible.
        </Typography>
      </AlertDialog>
    </>
  );
};

export default EditPresetDialog;
