/* components */
import DataTableList from "src/components/common/data-display/data-table-list";
import FavouriteToggle from "src/components/common/inputs/favourite-toggle";
import TikTokProfileGrid from "src/pages/listing/tiktok/tiktok-profile-grid";
import { NUMBER_CELL_TRANSLATE, showActionWhenHovered } from "src/components/common/data-display/dnd-table";
/* 3rd party lib */
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { MRT_ColumnDef } from "material-react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/pro-regular-svg-icons";
import React, { useMemo, useState } from "react";
import { Box, IconButton, Paper, Stack, Tooltip, Typography, useTheme } from "@mui/material";
/* Util */
import routes from "src/routes";
import TikTok from "src/images/tiktok.png";
import useHttp from "src/hooks/use-http";
import { TTikTokData } from "src/types/tiktok";
import { TABLE_ID } from "src/utils/constants";
import { formatNumberWithSuffix, getDynamicIndex } from "src/utils/general";
import { handleAddFavourite } from "src/components/profile/tiktok/profile-banner";
import { useSelector } from "react-redux";
import { RootState } from "src/store/root-reducer";
import useBackground from "src/hooks/use-background";
import { TSocialMedia, TUserSlot } from "src/types/common";
import AddAccountButton from "src/components/common/inputs/add-account-button";
import useMediaQueries from "src/hooks/use-mediaqueries";
import { TDeleteDialog } from "src/pages/listing/index";
import { faCircleX, faTrashCan } from "@fortawesome/pro-solid-svg-icons";
import NoDataFound from "src/components/common/feedback/no-data-found";

interface TikTokProfileListingProps {
  id: string;
  dataTableListRef?: any;
  influencerType?: string;
  search?: string;
  favourite?: boolean;
  multiSelect?: boolean;
  multiPostSelect?: boolean;
  columnVisibility?: any;
  showTrackButton?: boolean;
  showGlobalFilter?: boolean;
  tiktokProfileGridRef?: any;
  userSlotData?: TUserSlot | undefined;
  sort?: Record<string, string> | null;
  setSearch?: React.Dispatch<React.SetStateAction<string>>;
  onSelectProfile?: (profile: TTikTokData) => void;
  setAddAccountDialog?: React.Dispatch<
    React.SetStateAction<{
      open: boolean;
      socialMedia: TSocialMedia;
    }>
  >;
  setCompareAddAccountDialog?: React.Dispatch<
    React.SetStateAction<{
      open: boolean;
      socialMedia: TSocialMedia;
    }>
  >;
  setShowDeleteDialog?: React.Dispatch<React.SetStateAction<TDeleteDialog>>;
}

type Props = TikTokProfileListingProps;

const TikTokProfileListing: React.FC<Props> = ({
  id,
  sort,
  search,
  influencerType,
  setSearch,
  userSlotData,
  columnVisibility,
  showGlobalFilter,
  favourite = false,
  dataTableListRef,
  onSelectProfile,
  setAddAccountDialog,
  setShowDeleteDialog,
  tiktokProfileGridRef,
  multiSelect = false,
  multiPostSelect = false,
  showTrackButton = false,
  setCompareAddAccountDialog,
}) => {
  /* ================================================== */
  /*  state */
  /* ================================================== */
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { smDown, xsUp, mdUp, smUp, lgUp, xlUp } = useMediaQueries();
  const background = useBackground({});
  const { http, apiEndpoint } = useHttp();
  const { viewMode } = useSelector((state: RootState) => state.layout);
  const { user } = useSelector((state: RootState) => state.auth);
  const [checkPagination, setCheckPagination] = useState({ currentPage: 0, rowsPerPage: 0 });

  let pageSize = 25;

  if (xlUp) {
    pageSize = 25;
  } else if (lgUp) {
    pageSize = 20;
  } else if (mdUp) {
    pageSize = 15;
  } else if (smUp) {
    pageSize = 10;
  } else if (xsUp) {
    pageSize = 5;
  }

  const columns: MRT_ColumnDef<{}>[] = useMemo(
    () => [
      {
        accessorKey: "no",
        header: "#",
        size: 0,
        muiTableHeadCellProps: {
          sx: {
            ...NUMBER_CELL_TRANSLATE,
          },
        },
        enablePinning: false,
        // enableSorting: false,
        enableColumnActions: false,
        enableColumnFilter: false,
        enableColumnOrdering: false,
        enableColumnDragging: false,
        Cell: ({ row }) => {
          const { currentPage, rowsPerPage } = checkPagination;
          return (
            <Typography variant="body2" color="text.secondary" sx={{ textAlign: "center" }}>
              {getDynamicIndex(currentPage - 1, rowsPerPage, row.index + 1)}.
            </Typography>
          );
        },
      },
      {
        accessorKey: "favourite",
        header: "Favourite",
        muiTableHeadCellProps: {
          sx: {
            ...NUMBER_CELL_TRANSLATE,
          },
        },
        size: 0,
        enablePinning: false,
        // enableSorting: false,
        enableColumnActions: false,
        enableColumnFilter: false,
        enableColumnOrdering: false,
        enableColumnDragging: false,
        Cell: ({ row }) => {
          const rowData = row.original as TTikTokData;
          const { username } = rowData;

          return (
            <FavouriteToggle
              data={rowData}
              handleUpdateFavourite={() => {
                if (dataTableListRef.current) {
                  handleAddFavourite({
                    username: username,
                    data: rowData,
                    http: http,
                    apiEndpoint: apiEndpoint,
                    mutate: () => dataTableListRef.current.refreshData(),
                    t: t,
                    socialMedia: "tiktok",
                  });
                }
              }}
            />
          );
        },
      },
      {
        accessorKey: "downloaded_image", //access nested data with dot notation
        header: "",
        size: 120,
        // enableSorting: false,
        enableColumnFilter: false,
        enableColumnActions: false,
        muiTableHeadCellProps: {
          sx: {
            ...showActionWhenHovered(2, 2),
          },
        },
        Cell: ({ row }) => {
          const { username, downloaded_image } = row.original as TTikTokData;
          // return downloaded_image && <img alt={`${username}`} src={downloaded_image} width="80px" height="80px" />;
          return (
            downloaded_image && (
              <Box sx={{ position: "relative", width: "100px", height: "100px" }}>
                <Box sx={{ borderRadius: "50%", overflow: "hidden", border: "1px solid #dcdcdc", width: "100px", height: "100px" }}>
                  <img alt={`${username}`} src={downloaded_image} style={{ width: "100%", height: "100%", objectFit: "cover" }} />;
                </Box>
                <Box
                  sx={{
                    position: "absolute",
                    bottom: 0,
                    right: 0,
                    width: "20px",
                    height: "20px",
                    background: "white",
                    border: "1px solid #dcdcdc",
                    borderRadius: "50%",
                    padding: 0.5,
                    display: "grid",
                    placeItems: "center",
                  }}
                >
                  <img src={TikTok} alt="tiktok" width="100%" height="100%" />
                </Box>
              </Box>
            )
          );
        },
      },
      {
        accessorKey: "username",
        header: "Username",
        muiTableHeadCellProps: {
          sx: {
            ...showActionWhenHovered(2, 2),
          },
        },
        enableColumnFilter: false,
        Cell: ({ row }) => {
          const { username, display_name } = row.original as TTikTokData;
          // return downloaded_image && <img alt={`${username}`} src={downloaded_image} width="80px" height="80px" />;
          return (
            <Stack>
              {onSelectProfile ? (
                <Stack>
                  <Typography color="inherit">{display_name}</Typography>
                  <Typography color="inherit" variant="caption">
                    @{username}
                  </Typography>
                </Stack>
              ) : (
                <a
                  href={`${routes.TIKTOK_PROFILE}/${username}`}
                  style={{ color: theme.palette.primary.main, textDecoration: "underline", fontWeight: "bold" }}
                >
                  <Typography color="inherit">{display_name}</Typography>
                  <Typography color="inherit" variant="caption">
                    @{username}
                  </Typography>
                </a>
              )}
            </Stack>
          );
        },
      },
      {
        accessorKey: "influencer_type", //normal accessorKey
        header: "Type",
        muiTableHeadCellProps: {
          sx: {
            ...showActionWhenHovered(2, 2),
          },
        },
        enableColumnFilter: false,
        size: 100,
        Cell: ({ row }) => {
          const rowData = row.original as TTikTokData;
          const title = (): string => {
            switch (rowData.influencer_type) {
              case "nano":
                return "Nano (1,000 - 10,000)";
              case "micro":
                return "Micro (10,000 - 100,000)";
              case "macro":
                return "Macro (100,000 - 1 million)";
              case "mega":
                return "Mega (Over 1 million)";
              case "unknown":
                return "Unknown";
              default:
                return "Influencer Type"; // Display when 'null' or no value is selected
            }
          };

          return (
            <Tooltip title={title()}>
              <Typography variant="body2" textTransform={"capitalize"}>
                {rowData.influencer_type}
              </Typography>
            </Tooltip>
          );
        },
      },
      {
        accessorKey: "count_follower", //normal accessorKey
        header: "Followers",
        muiTableHeadCellProps: {
          sx: {
            ...showActionWhenHovered(2, 2),
          },
        },
        enableColumnFilter: false,
        size: 130,
        Cell: ({ row }) => {
          const rowData = row.original as TTikTokData;
          return <Typography variant="body1">{formatNumberWithSuffix(rowData.count_follower) ?? ""}</Typography>;
        },
      },
      {
        accessorKey: "median_engagement_rate",
        header: "Engagement Rate",
        muiTableHeadCellProps: {
          sx: {
            ...showActionWhenHovered(2, 2),
          },
        },
        enableColumnFilter: false,
        size: 130,
        Cell: ({ row }) => {
          const rowData = row.original as TTikTokData;
          return <Typography variant="body1">{`${formatNumberWithSuffix(rowData.engagement_stats.median_engagement_rate)}%` ?? ""}</Typography>;
        },
      },
      {
        accessorKey: "total_play",
        header: "Total Plays",
        muiTableHeadCellProps: {
          sx: {
            ...showActionWhenHovered(2, 2),
          },
        },
        enableColumnFilter: false,
        size: 130,
        Cell: ({ row }) => {
          const rowData = row.original as TTikTokData;
          return <Typography variant="body1">{formatNumberWithSuffix(rowData.total_play) ?? ""}</Typography>;
        },
      },
      {
        accessorKey: "count_like",
        header: "Total Likes",
        muiTableHeadCellProps: {
          sx: {
            ...showActionWhenHovered(2, 2),
          },
        },
        enableColumnFilter: false,
        size: 130,
        Cell: ({ row }) => {
          const rowData = row.original as TTikTokData;
          return <Typography variant="body1">{formatNumberWithSuffix(rowData.total_like) ?? ""}</Typography>;
        },
      },
      {
        accessorKey: "total_comment",
        header: "Total Comments",
        muiTableHeadCellProps: {
          sx: {
            ...showActionWhenHovered(2, 2),
          },
        },
        enableColumnFilter: false,
        size: 160,
        Cell: ({ row }) => {
          const rowData = row.original as TTikTokData;
          return <Typography variant="body1">{formatNumberWithSuffix(rowData.total_comment) ?? ""}</Typography>;
        },
      },
      {
        accessorKey: "count_video",
        header: "Videos",
        muiTableHeadCellProps: {
          sx: {
            ...showActionWhenHovered(2, 2),
          },
        },
        enableColumnFilter: false,
        size: 130,
        Cell: ({ row }) => {
          const rowData = row.original as TTikTokData;
          return <Typography variant="body1">{formatNumberWithSuffix(rowData.count_video) ?? ""}</Typography>;
        },
      },
      {
        accessorKey: "total_saved",
        header: "Total Saved",
        muiTableHeadCellProps: {
          sx: {
            ...showActionWhenHovered(2, 2),
          },
        },
        enableColumnFilter: false,
        size: 130,
        Cell: ({ row }) => {
          const rowData = row.original as TTikTokData;
          return <Typography variant="body1">{formatNumberWithSuffix(rowData.total_saved) ?? ""}</Typography>;
        },
      },
      {
        accessorKey: "total_share",
        header: "Total Shared",
        muiTableHeadCellProps: {
          sx: {
            ...showActionWhenHovered(2, 2),
          },
        },
        enableColumnFilter: false,
        size: 130,
        Cell: ({ row }) => {
          const rowData = row.original as TTikTokData;
          return <Typography variant="body1">{formatNumberWithSuffix(rowData.total_share) ?? ""}</Typography>;
        },
      },
      {
        accessorKey: "count_following",
        header: "Following",
        muiTableHeadCellProps: {
          sx: {
            ...showActionWhenHovered(2, 2),
          },
        },
        enableColumnFilter: false,
        size: 130,
        Cell: ({ row }) => {
          const rowData = row.original as TTikTokData;
          return <Typography variant="body1">{formatNumberWithSuffix(rowData.count_following) ?? ""}</Typography>;
        },
      },
      {
        accessorKey: "action",
        header: "Action",
        muiTableHeadCellProps: {
          sx: {
            ...showActionWhenHovered(2, 2),
          },
        },
        size: 80,
        enableColumnFilter: false,
        Cell: ({ row }) => {
          const rowData = row.original as TTikTokData;
          return (
            <Box
              sx={{ display: "grid", placeItems: "center", height: "100px" }}
              onClick={e => {
                e.stopPropagation();
                setShowDeleteDialog && setShowDeleteDialog({ open: true, socialMedia: "tiktok", username: rowData.username });
              }}
            >
              <FontAwesomeIcon size="xl" icon={faTrashCan} color={theme.palette.error.main} />
            </Box>
          );
        },
      },
    ],
    [dataTableListRef, theme, setShowDeleteDialog, onSelectProfile, checkPagination, t, http, apiEndpoint]
  );

  const listMetadata = {
    primary: { path: "username" },
    sortFields: [
      { key: "username", label: "Username" },
      { key: "count_follower", label: "Followers" },
      { key: "count_following", label: "Following" },
      { key: "count_post", label: "Posts" },
      { key: "category_name", label: "Category" },
    ],
    fields: [
      {
        path: "count_follower",
        label: "Followers",
      },
      {
        path: "count_following",
        label: "Following",
      },
      {
        path: "count_post",
        label: "Posts",
      },
      {
        path: "category_name",
        label: "Category",
      },
    ],
    action: (data: TTikTokData) => (
      <IconButton
        onClick={e => {
          e.stopPropagation();
          handleNavigate(data);
        }}
      >
        <FontAwesomeIcon icon={faAngleRight} size="xs" />
      </IconButton>
    ),
    content: (d: TTikTokData, index: number) => {
      const { currentPage, rowsPerPage } = checkPagination;

      return (
        <Stack direction="row" alignItems="center" spacing={2} width={"100%"} onClick={() => handleNavigate(d)}>
          <Box sx={{ position: "relative", width: "50px", height: "50px" }}>
            <Box
              sx={{
                borderRadius: "50%",
                overflow: "hidden",
                border: "1px solid #dcdcdc",
                width: "50px",
                height: "50px",
              }}
            >
              <img src={d?.downloaded_image} alt={d?.username} style={{ width: "100%", height: "100%", objectFit: "cover" }} />
            </Box>
            <Box
              sx={{
                position: "absolute",
                bottom: -5,
                right: -5,
                width: "15px",
                height: "15px",
                background: "white",
                border: "1px solid #dcdcdc",
                borderRadius: "50%",
                padding: 0.5,
                display: "grid",
                placeItems: "center",
              }}
            >
              <img src={TikTok} alt="tiktok" width="100%" height="100%" />
            </Box>
          </Box>
          <Stack sx={{ textAlign: "left", width: "calc(100% - 70px)" }}>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Stack direction="row" alignItems="center" spacing={0.5}>
                <Typography variant="body2" color="text.secondary" sx={{ textAlign: "center" }}>
                  {getDynamicIndex(currentPage - 1, rowsPerPage, index + 1)}.
                </Typography>

                <Typography variant={"body1"} fontWeight={700} color="text.secondary">
                  {d ? d.display_name : "-"}
                </Typography>
              </Stack>

              <Stack direction="row" alignItems="center" spacing={1}>
                {id !== "profile_dialog" && id !== "post_dialog" && (
                  <>
                    {(user?.isAdmin || (!user?.isAdmin && userSlotData && userSlotData.slot_available <= 0)) && (
                      <FontAwesomeIcon
                        onClick={e => {
                          e.stopPropagation();
                          setShowDeleteDialog && setShowDeleteDialog({ open: true, socialMedia: "tiktok", username: d.username });
                        }}
                        size="lg"
                        icon={faTrashCan}
                        color={theme.palette.error.main}
                      />
                    )}
                    <FavouriteToggle
                      data={d}
                      height={"auto"}
                      handleUpdateFavourite={() => {
                        if (dataTableListRef.current) {
                          handleAddFavourite({
                            username: d.username,
                            data: d,
                            http: http,
                            apiEndpoint: apiEndpoint,
                            mutate: () => dataTableListRef.current.refreshData(),
                            t: t,
                            socialMedia: "tiktok",
                          });
                        }
                      }}
                    />
                  </>
                )}
              </Stack>
            </Stack>

            {d.description && (
              <Typography
                sx={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
                variant={"caption"}
              >
                {d.description}
              </Typography>
            )}

            <Stack direction="row" spacing={1} sx={{ mt: 0.5, pt: 0.5, borderTop: "0.5px solid #bcbcbc" }}>
              <Typography
                sx={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
                variant={"caption"}
                color="text.secondary"
              >
                Followers:&nbsp;<span style={{ fontWeight: "bolder" }}>{formatNumberWithSuffix(d.count_follower)}</span>
              </Typography>
              <Typography
                sx={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
                variant={"caption"}
                color="text.secondary"
              >
                Following:&nbsp;<span style={{ fontWeight: "bolder" }}>{formatNumberWithSuffix(d.count_following)}</span>
              </Typography>
              <Typography
                sx={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
                variant={"caption"}
                color="text.secondary"
              >
                Videos:&nbsp;<span style={{ fontWeight: "bolder" }}>{formatNumberWithSuffix(d.count_video)}</span>
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      );
    },
  };

  const gridComponent = (
    <TikTokProfileGrid
      id={id}
      sort={sort}
      search={search}
      setSearch={setSearch}
      favourite={favourite}
      multiSelect={multiSelect}
      userSlotData={userSlotData}
      influencerType={influencerType}
      multiPostSelect={multiPostSelect}
      showTrackButton={showTrackButton}
      onSelectProfile={onSelectProfile}
      setAddAccountDialog={setAddAccountDialog}
      setShowDeleteDialog={setShowDeleteDialog}
      tiktokProfileGridRef={tiktokProfileGridRef}
      setCompareAddAccountDialog={setCompareAddAccountDialog}
    />
  );

  /* ================================================== */
  /*  method */
  /* ================================================== */
  const handleNavigate = (rowData: TTikTokData) => {
    if (onSelectProfile) {
      onSelectProfile(rowData);
    } else {
      navigate(`${routes.TIKTOK_PROFILE}/${rowData.username}`, { state: { tablePage: checkPagination.currentPage } });
    }
  };
  /* ================================================== */
  /*  useEffect */
  /* ================================================== */

  /* ================================================== */
  /* ================================================== */
  return !showTrackButton && !favourite ? (
    // this is to show grid for profile dialog
    gridComponent
  ) : (
    <>
      {viewMode === "grid" ? (
        gridComponent
      ) : (
        <Stack spacing={2}>
          <Paper
            elevation={5}
            sx={{
              width: "100%",
              background: background, //set bg darker for profile dialog
            }}
          >
            {/*  <Paper elevation={5} sx={{ width: "100%", mb: "50px", background: '#000000' }}> */}
            <Stack sx={{ p: smDown ? 1 : showTrackButton ? 4 : 1 }} spacing={2}>
              {showTrackButton && (
                <AddAccountButton
                  viewMode={viewMode}
                  handleAction={() => {
                    setAddAccountDialog && setAddAccountDialog({ open: true, socialMedia: "tiktok" });
                  }}
                />
              )}
              <DataTableList
                rowClick
                listItemStyle={{
                  px: 1,
                }}
                columnVisibility={columnVisibility}
                columnPinning={{ left: [], right: ["action"] }}
                mobilePaginationStickBottom={false}
                customNoDataComponent={
                  search && (
                    <Paper sx={{ p: 2 }}>
                      <NoDataFound image />
                    </Paper>
                  )
                }
                customFilters={{
                  page_size: pageSize,
                  q: search,
                  favourite: favourite ? true : false,
                  influencer_type: influencerType === "null" ? "" : influencerType,
                }}
                // if user is not admin and slot available is more than 0 show columns that are not action/hide action column
                columns={columns.filter(child =>
                  !user?.isAdmin && userSlotData?.slot_available && userSlotData?.slot_available > 0 ? child.accessorKey !== "action" : child
                )}
                id={`${TABLE_ID.TIKTOK_LISTING}_${id}`}
                showGlobalFilter={showGlobalFilter ? true : false}
                listMetadata={listMetadata}
                dataTableListRef={dataTableListRef}
                anchorRedirect={(rowData: TTikTokData) => `${routes.TIKTOK_PROFILE}/${rowData.username}`}
                setCheckPagination={setCheckPagination}
                columnOrder={columns.map(c => c.accessorKey)}
                rowAction={handleNavigate}
                // rowActions={[
                //   {
                //     icon: faAngleRight,
                //     label: "More",
                //     onClick: handleNavigate,
                //     tooltip: () => "More",
                //   },
                // ]}
                search={{
                  key: "q",
                  label: "Search username",
                }}
                // url={onSelectProfile ? `${apiEndpoint.TIKTOK}` : `${apiEndpoint.TIKTOK}${favourite ? `?favourite=true` : ""}`}
                url={apiEndpoint.TIKTOK}
              />
              {showTrackButton && search !== "" && (
                <Typography variant="body2" sx={{ pt: 1, cursor: "pointer" }} onClick={() => setSearch && setSearch("")}>
                  <u>
                    Clear Filter &nbsp;
                    <FontAwesomeIcon icon={faCircleX} />
                  </u>
                </Typography>
              )}
            </Stack>
          </Paper>
          {/* {viewMode === "table" && search !== "" && hasData && (
          <Box>
            <Typography variant="body1" color="text.secondary" textAlign={"center"}>
              Not what you are looking for?&nbsp;
              <Link sx={{ cursor: "pointer" }} onClick={() => navigate(routes.SEARCH)}>
                Click here
              </Link>
            </Typography>
          </Box>
        )} */}
        </Stack>
      )}
    </>
  );
};

export default TikTokProfileListing;
