import React, { useEffect } from "react";
/* components */
/* 3rd party lib */
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import {
  alpha,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Paper,
  Stack,
  styled,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import DataDisplay from "src/components/common/inputs/data-display";
import { useLocation } from "react-router-dom";
import useMediaQueries from "src/hooks/use-mediaqueries";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/pro-regular-svg-icons";
/* Util */
interface AdminEditDialogProps {
  open: boolean;
  loading?: boolean;
  onClose: () => void;
  handleConfirm: (e: any) => void;
}

const CustomSwitch = styled(Switch)(({ theme }) => {
  const { mode } = theme.palette;
  return {
    "& .MuiSwitch-switchBase": {
      color: mode === "dark" ? (theme.palette as any).neutral[400] : "default",
    },
    "& .MuiSwitch-switchBase + .MuiSwitch-track": {
      backgroundColor: mode === "dark" ? (theme.palette as any).neutral[500] : "default",
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: mode === "dark" ? theme.palette.primary.main : "default",
      "&:hover": {
        backgroundColor: mode === "dark" ? alpha(theme.palette.primary.main, theme.palette.action.hoverOpacity) : "default",
      },
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      backgroundColor: mode === "dark" ? theme.palette.primary.main : "default",
    },
  };
});

interface IFormValues {
  email: string;
  new_password: string;
  is_active: boolean;
  phone_number: string;
}

type Props = AdminEditDialogProps;

const AdminEditDialog: React.FC<Props> = ({ open, loading, onClose, handleConfirm }) => {
  /* ================================================== */
  /*  state */
  /* ================================================== */

  const location = useLocation();
  const { smDown } = useMediaQueries();
  const locationParam = location.state;
  const formik = useFormik<IFormValues>({
    validateOnChange: false,
    initialValues: {
      email: "",
      new_password: "",
      phone_number: "",
      is_active: false,
    },
    onSubmit: values => {},
  });

  /* ================================================== */
  /*  method */
  /* ================================================== */
  /* ================================================== */
  /*  useEffect */
  /* ================================================== */

  useEffect(() => {
    if (locationParam && "userId" in locationParam && locationParam.userId) {
      formik.setFieldValue("email", locationParam.userId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationParam]);

  /* ================================================== */
  /* ================================================== */
  return (
    <Dialog maxWidth="md" fullWidth={smDown} open={open} onClose={onClose}>
      <DialogTitle sx={{ p: 2 }}>
        <Stack alignItems={"center"} direction="row" spacing={3}>
          <Typography variant="inherit" sx={{ flexGrow: 1 }}>
            Edit User Account
          </Typography>
          <IconButton onClick={onClose}>
            <CloseRoundedIcon fontSize="small" />
          </IconButton>
        </Stack>
      </DialogTitle>

      <form id="credential-form" onSubmit={formik.handleSubmit}>
        <DialogContent>
          <Paper sx={{ p: 2 }} elevation={5}>
            <Grid container spacing={2}>
              <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
                <FormGroup sx={{ userSelect: "none" }}>
                  <Tooltip arrow title={formik.values.is_active ? "Toggle to disable user's login" : "Toggle to enable user's login"}>
                    <FormControlLabel
                      control={<CustomSwitch name="is_active" />}
                      checked={formik.values.is_active}
                      onChange={(e, checked) => formik.setFieldValue("is_active", checked)}
                      label={formik.values.is_active ? "Active" : "Inactive"}
                    />
                  </Tooltip>
                </FormGroup>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <DataDisplay
                    label={"Email"}
                    variant={"body2"}
                    value={
                      <TextField
                        variant="outlined"
                        placeholder="Enter user email"
                        hiddenLabel
                        name="email"
                        fullWidth
                        size="small"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        error={formik.errors.email ? true : false}
                        helperText={formik.errors.email}
                        inputProps={{
                          readOnly: !formik.values.is_active,
                        }}
                        disabled={!formik.values.is_active}
                      />
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <DataDisplay
                    label={"Phone"}
                    variant={"body2"}
                    value={
                      <TextField
                        variant="outlined"
                        placeholder="+65"
                        hiddenLabel
                        name="phone_number"
                        fullWidth
                        size="small"
                        value={formik.values.phone_number}
                        onChange={formik.handleChange}
                        error={formik.errors.phone_number ? true : false}
                        helperText={
                          formik.errors && formik.errors.phone_number ? (
                            formik.errors.phone_number
                          ) : (
                            <Stack direction="row" alignItems="center" spacing={1}>
                              <FontAwesomeIcon icon={faCircleInfo} size="lg" />
                              <Typography variant="caption" color="text.secondary">
                                Please contact admin to make changes to phone number
                              </Typography>
                            </Stack>
                          )
                        }
                        inputProps={{
                          readOnly: !formik.values.is_active,
                        }}
                        disabled={!formik.values.is_active}
                      />
                    }
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Paper>
        </DialogContent>

        <DialogActions sx={{ p: 2 }}>
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>
          <Button form="credential-form" disabled={loading} variant="contained" color={"primary"} onClick={handleConfirm} autoFocus>
            Save
            {loading && <CircularProgress sx={{ ml: 1 }} size={18} color="inherit" />}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AdminEditDialog;
